import React from "react";
import {
    arrowRightICon,
    checkRadioImage,
    unCheckRadioImage
} from "./assets";
import WasteListSortController, {
    Props
} from "./WasteListSortController";
import Strings from "./Strings";

export default class WasteListSort extends WasteListSortController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        let { querySearchState: { sort_by } } = this.props;
        return (
            <div className={this.state.switchLanguage === 'english' ? "sort_modal_wrapper" : "sort_modal_wrapper_arabic"}>
                {/* sort modal */}
                <div className="sort_menu_container sort_menu_title_container">
                    <p className="sort_menu_text sort_menu_title">Sort</p>
                </div>
                {/* sort option newest */}
                <div className="sort_menu_container">
                    <div data-test-id="sort_created_at" className="sort_menu_image_container" onClick={this.setSortType('created_at')}>
                        {sort_by === 'created_at' && <img src={checkRadioImage} alt="checkradioIcon" className="checkRadioImage" />}
                        <img src={unCheckRadioImage} alt="uncheckradioIcon" className="unCheckRadioImage" />
                    </div>
                    <p className="sort_menu_text">{Strings.newest}</p>
                </div>
                {/* sort option ascending */}
                <div className="sort_menu_container">
                    <div data-test-id="sort_asc" className="sort_menu_image_container" onClick={this.setSortType('asc')}>
                        {sort_by === 'asc' && <img src={checkRadioImage} alt="checkradioIcon" className="checkRadioImage" />}
                        <img src={unCheckRadioImage} alt="uncheckradioIcon" className="unCheckRadioImage" />
                    </div>
                    <p className="sort_menu_text">{Strings.ascendingA}<img src={arrowRightICon} alt="righticon" className="sort_menu_text_icon" />{Strings.z}</p>
                </div>
                {/* sort option descending */}
                <div className="sort_menu_container"
                    style={{ marginBottom: '2px' }}
                >
                    <div data-test-id="sort_desc"
                        className="sort_menu_image_container"
                        onClick={this.setSortType('desc')}
                    >
                        {
                            sort_by === 'desc' &&
                            <img src={checkRadioImage} alt="checkradioIcon" className="checkRadioImage" />
                        }
                        <img src={unCheckRadioImage} alt="uncheckradioIcon" className="unCheckRadioImage" />
                    </div>
                    <p className="sort_menu_text">
                        {Strings.descendingZ}
                        <img src={arrowRightICon} alt="righticon" className="sort_menu_text_icon" />
                        {Strings.a}
                    </p>
                </div>
            </div>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
// Customizable Area End