// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {getName,} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRequest, handleResponseDataObject } from "./requestHelper";
import { TimeChoice } from "./DashboardController.web";
import { isEqual, maxBy } from "lodash";
import { webStyle } from "./Dashboard.web";
import moment from "moment";
import { defaultDataEnvironmentImpact } from "./DashboardClientController.web";
import { VALUE_FILTER_REPORT } from "./CustomFilter.web";
import { downloadFile } from "../../../components/src/DownloadPdf.web";
import { getStorageData } from "framework/src/Utilities";

export const VALUE_SORT_TOTAL_WEIGHT = {
  NEWEST_TO_OLDEST: "created_by_desc",
  OLDEST_TO_NEWEST: "created_by_asc",
  ASCENDING_A_Z: "asc",
  DESCENDING_Z_A: "desc",
};

export interface SearchQueryType {
  categories: Array<any>;
  branches: Array<any>;
  custom_start_date: string;
  custom_end_date: string;
  sort_by: string;
}

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
}
interface S {
  chosenTime: TimeChoice;
  listData: Array<number>;
  listLabel: Array<string>;
  maxChart: number;
  tableData: Array<any>;
  showSortModal: boolean;
  enviromentalCardsData: Array<{
    title: string;
    value: number;
    icon: any;
    unit: string;
  }>;
  showFilterModal: boolean;
  totalPagination: number;
  page: number;
  netWeightSortBy: string;
  totalNetWeightCount: number;
  categoryList: Array<[number, string]>;
  branchList: Array<any>;
  searchQueryState: SearchQueryType;
  contractId: number;
}
interface SS {}
// Customizable Area End
export default class ReportClientController extends BlockComponent<
  Props,
  S,
  SS
> {
// Customizable Area Start
  getRecycleCollectionApi: string = "";
  getEnvironmentalImpactApi: string = "";
  getTotalNetWeightApi: string = "";
  getCategoryListApi: string = "";
  getBranchListApi: string = "";
  getCurrentContractIdApi: string = "";
  getprofessionsDataCallID:string = "";
  initialFilters = {
    categories: [],
    branches: [],
    custom_start_date: moment()
      .subtract(1, "days")
      .format("DD-MM-yyyy"),
    custom_end_date: moment().format("DD-MM-yyyy"),
    sort_by: "created_at",
  };
// Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
// Customizable Area Start
      listData: [],
      listLabel: [],
      chosenTime: TimeChoice.MONTH,
      maxChart: 0,
      tableData: [],
      showSortModal: false,
      enviromentalCardsData: defaultDataEnvironmentImpact,
      showFilterModal: false,
      page: 1,
      totalPagination: 1,
      totalNetWeightCount: 1,
      netWeightSortBy: VALUE_SORT_TOTAL_WEIGHT.NEWEST_TO_OLDEST,
      branchList: [],
      categoryList: [],
      searchQueryState: {
        ...this.initialFilters,
      },
      contractId: 0,
// Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
// Customizable Area Start
    super.componentDidMount();
    this.getEnvironmentalImpact();
    this.getRecycleCollection();
    this.getTotalNetWeight();
    this.getCategoryList();
    this.getBranchList();
    this.getCurrentContractId();
    window.addEventListener("beforeunload", this.handleBeforeUnload);
// Customizable Area End
  }

  async receive(from: string, message: Message) {
// Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getEnvironmentalImpactApi:
            const response = [
              responseJson.tree_saved,
              responseJson.co2_eq_reduction,
              responseJson.water_saved,
              responseJson.kilo_watt_of_energy_saved,
              responseJson.landfill_space_saved,
              responseJson.reduction_petroleum_use,
            ];

            const newData = [...defaultDataEnvironmentImpact].map(
              (
                item: {
                  title: string;
                  value: number;
                  icon: any;
                  unit: string;
                },
                index: number
              ) => ({
                ...item,
                value: response[index] || 0,
              })
            );

            this.setState({ enviromentalCardsData: newData });
            break;
          case this.getTotalNetWeightApi:
            this.setState({
              tableData: responseJson.data,
              totalPagination: responseJson.meta.pagy.pages,
              totalNetWeightCount: responseJson.meta.pagy.count,
            });
            break;
          case this.getBranchListApi:
            this.setState({ branchList: responseJson.data });
            break;
          case this.getCurrentContractIdApi:
            this.setState({
              contractId:
                this.getLatestContract(responseJson.data.attributes.contracts)
                  ?.contract_id ?? "",
            });
            break;
          case this.getCategoryListApi:
            const arrayCategory = responseJson.waste_type_categories.map(
              (item: [number, string]) => ({
                id: item[0],
                name: item[1],
              })
            );
            this.setState({ categoryList: arrayCategory });
            break;
          case this.getRecycleCollectionApi:
            const dataList: any = handleResponseDataObject({
              inputData: responseJson.recycling_collection,
              timeChoice: this.state.chosenTime,
              isLineChart: this.state.chosenTime === TimeChoice.MONTH,
              startDate: this.getDateOnCustomFilter("startDate"),
              endDate: this.getDateOnCustomFilter("endDate")
            });
            this.setState({
              listData: dataList.data,
              listLabel: dataList.label,
              maxChart: Math.max(dataList.data),
            });
            break;
            case this.getprofessionsDataCallID:
              this.getReciveDownloadPDf(responseJson)
              break;
        }
      } else if(responseJson.errors){
        // Empty Data handler
        switch (apiRequestCallId) {
          case this.getRecycleCollectionApi:
            const dataList: any = handleResponseDataObject({
              inputData: {},
              timeChoice: this.state.chosenTime,
              isLineChart: this.state.chosenTime === TimeChoice.MONTH,
              startDate: "",
              endDate: ""
            });
            this.setState({
              listData: [],
              listLabel: dataList.label,
              maxChart: Math.max(dataList.data),
            });
            break;
          case this.getTotalNetWeightApi:
            this.setState({
              tableData: [],
              totalPagination: 1,
              totalNetWeightCount: 0,
            });
            break;
        }
      }
    }
// Customizable Area End
  }
// Customizable Area Start
  getLatestContract(data: Array<any>) {
    if (data.length > 0) {
      return maxBy(data, "contract_id");
    } else return "";
  }

  getReciveDownloadPDf = (responseJson: { url: string }) => {
    downloadFile(responseJson.url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = "document.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
  }
  
  async getTotalNetWeight() {
    const token = this.getTokenForClientReport();
    const detail: any = localStorage.getItem("userDetail");
    const userDetailObj: any = JSON.parse(detail);
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTotalNetWeightApi = request.messageId;

    createRequest({
      method: "GET",
      token,
      requestMsg: request,
      endPoint: `${
        webConfigJSON.getTotalNetWeightEndPoint
      }?per_page=10&account_id=${this.getAccountId(userDetailObj)}&page=${
        this.state.page
      }&sort_by=${this.state.netWeightSortBy}${this.getFilterUrl()}`,
    });
  }

  getTokenForClientReport = () => {
    const clientDetails: any = sessionStorage.getItem("clientDetails");
    const token = localStorage.getItem("token");
    return (JSON.parse(clientDetails) || {}).token || token;
  };

  getAccountId = (userDetailObj: any) => {
    const clientDetails: any = sessionStorage.getItem("clientDetails");
    const userAccountId = userDetailObj.account.id;
    return clientDetails ? JSON.parse(clientDetails).id : userAccountId;
  };

  getFilterUrl() {
    let params = "";
    if (this.state.searchQueryState.sort_by !== "") {
      if (
        this.state.searchQueryState.sort_by === VALUE_FILTER_REPORT.LAST_WEEK
      ) {
        params += "&filters[time_period]=last_7_days";
      } else if (
        this.state.searchQueryState.sort_by === VALUE_FILTER_REPORT.LAST_MONTH
      ) {
        params += "&filters[time_period]=last_30_days";
      } else if (
        this.state.searchQueryState.sort_by ===
        VALUE_FILTER_REPORT.CURRENT_CONTRACT
      ) {
        params += `&filters[time_period][current_contract_period]=${this.state.contractId}`;
      } else if (
        this.state.searchQueryState.sort_by === VALUE_FILTER_REPORT.CUSTOM
      ) {
        const startDate = moment(
          this.state.searchQueryState.custom_start_date,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        const endDate = moment(
          this.state.searchQueryState.custom_end_date,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        params += `&filters[time_period][custom_date][start_date]=${startDate}&filters[time_period][custom_date][end_date]=${endDate}`;
      }
    }
    if (
      this.state.searchQueryState.branches.length !== 0 ||
      this.state.searchQueryState.categories.length !== 0
    ) {
      params += "&";
      this.state.searchQueryState.branches.forEach((branch) => {
        params += `filters[branch_name_ids][]=${branch}&`;
      });
      this.state.searchQueryState.categories.forEach((category) => {
        params += `filters[waste_type_categories_ids][]=${category}&`;
      });
      return params;
    }
    return params;
  }

  handleChangeTimeChart(time: TimeChoice) {
    this.setState({
      chosenTime: time,
      listData: [],
      listLabel: [],
      maxChart: 0,
    });
  }

  getFilterTime() {
    if (this.state.chosenTime === TimeChoice.MONTH)
      return "&recycling_collection_filter=monthly";
    else if (this.state.chosenTime === TimeChoice.YEAR)
      return "&recycling_collection_filter=yearly";
    else return "";
  }

  getGenerateReportTime() {
    if (this.state.chosenTime === TimeChoice.MONTH)
      return "&recycling_collection_filter=monthly";
    else if (this.state.chosenTime === TimeChoice.YEAR)
      return "&recycling_collection_filter=yearly";
    else return "";
  }

  async getCategoryList() {
    const token = this.getTokenForClientReport();
    const detail: any = localStorage.getItem("userDetail");
    const userDetailObj: any = JSON.parse(detail);
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCategoryListApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${this.getAccountId(userDetailObj)}/${
        webConfigJSON.categoryListUrl
      }`,
    });
  }

  async getCurrentContractId() {
    const token = this.getTokenForClientReport();
    const detail: any = localStorage.getItem("userDetail");
    const userDetailObj: any = JSON.parse(detail);
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCurrentContractIdApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${webConfigJSON.accountsUrl}/${this.getAccountId(
        userDetailObj
      )}`,
    });
  }

  async getBranchList() {
    const token = this.getTokenForClientReport();
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getBranchListApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${webConfigJSON.branchListUrl}`,
    });
  }

  async getRecycleCollection() {
    const token = this.getTokenForClientReport();
    const detail: any = localStorage.getItem("userDetail");
    const userDetailObj: any = JSON.parse(detail);
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRecycleCollectionApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${
        webConfigJSON.dashboardGetRecycleCollectionUrl
      }?account_id=${this.getAccountId(
        userDetailObj
      )}${this.getFilterTime()}${this.getFilterUrl()}`,
    });
  }

    exportReport = async() => {
    let token= await getStorageData("token")
    const detail = await getStorageData('userDetail')
    const userDetailObj = JSON.parse(detail)
    const headers = {
      "token": token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${this.getAccountId(userDetailObj)}/${webConfigJSON.exportPDFURL}?account_id=${this.getAccountId(userDetailObj)}${this.getGenerateReportTime()}${this.getFilterUrl()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getprofessionsDataCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getEnvironmentalImpact() {
    const token = this.getTokenForClientReport();
    const detail: any = localStorage.getItem("userDetail");
    const userDetailObj: any = JSON.parse(detail);
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEnvironmentalImpactApi = request.messageId;

    createRequest({
      endPoint: `${
        webConfigJSON.environmentalImpactEndPoint
      }?account_id=${this.getAccountId(userDetailObj)}${this.getFilterUrl()}`,
      requestMsg: request,
      token,
      method: "GET",
    });
  }

  handleShowFilter() {
    this.setState({ showFilterModal: true });
  }

  hideFilter() {
    this.setState({ showFilterModal: false });
  }

  handleShowSort() {
    this.setState({ showSortModal: true });
  }

  checkCurrentStyle(choice: TimeChoice) {
    if (this.state.chosenTime === choice) return webStyle.chosenTimeText;
    else return webStyle.unchosenTimeText;
  }

  getDateOnCustomFilter(dateType: string) {
    if(dateType == "startDate"){return this.state.searchQueryState.sort_by === VALUE_FILTER_REPORT.CUSTOM ? this.state.searchQueryState.custom_start_date : ""}
    if(dateType == "endDate"){return this.state.searchQueryState.sort_by === VALUE_FILTER_REPORT.CUSTOM ? this.state.searchQueryState.custom_end_date : ""}
  }

  componentDidUpdate(prevProps: any, prevState: S) {
    if (
      prevState.netWeightSortBy !== this.state.netWeightSortBy ||
      prevState.page !== this.state.page
    ) {
      this.getTotalNetWeight();
    }
    if (prevState.chosenTime !== this.state.chosenTime) {
      this.getRecycleCollection();
    }
    if (!isEqual(prevState.searchQueryState, this.state.searchQueryState)) {
      this.getEnvironmentalImpact();
      this.getRecycleCollection();
      this.getTotalNetWeight();
    }
  }

  handleClearFilters = () => {
    this.setState({ searchQueryState: { ...this.initialFilters } });
  };

  handleBeforeUnload = () => {
    localStorage.removeItem("clientDetails");
  };

  componentWillUnmount(): any {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }
}
// Customizable Area End