Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";
exports.httpPutMethod = "PUT";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";
exports.getContactInfoAPiEndPoint = "contact_us/contact_info";
exports.submitContactformAPiEndPoint = "contact_us/contacts";
exports.setLanguageEndpoint = "language_options/languages/set_user_language";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";
exports.suceesMessageUserForm = "Your message has been successfully sent!"
exports.defaultsErrorMessageUserForm = "Message couldn't be sent. Please try again!"

exports.btnExampleTitle = "CLICK ME";
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.mobileRegex = /^(?:\+\d{1,3}\s?)?\d{8,14}$/;
exports.urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.?)+[A-Za-z]{2,6}\.?|[A-Za-z0-9-]{1,}(?<!-)\.?|localhost)(?::\d{1,5})?(?:[/?#]\S*)?$/
exports.contactUsEndpoint = '/contact_us/contact_info'

exports.englishLanguage={
  contact:"Contact",
  contactUs:"Contact Us",
  yourName:"Your name",
  phoneNumber:'Phone number',
  heading1:"Heading 1",
  chat:"Chat",
  enterYourMessage:"Enter your message",
  submit:"Submit",
  enterHeading:"Enter heading",
  subHeading1:"Sub-heading 1",
  enterSubHeading:"Enter sub-heading",
  emailAddress:"Email Address",
  enterEmailAddress:"Enter email address",
  office:"Office",
  address:"Address",
  enterAddress:"Enter Address",
  contactNumbers:"Contact Numbers",
  follow:"Follow",
  uploadURL:"Upload URL",
  discardChanges:"Discard Changes",
  save:"Save",
  yourChangesHaveBeenSaved:"Your changes have been saved!",
  enterContactNumber1:"Enter contact number 1",
  enterContactNumber2:"Enter contact number 2",
  enterContactNumber3:"Enter contact number 3",
  enterWebsiteURL:"Enter website URL"
}

exports.arabicLanguage={
  contact:"تواصل معنا",
  contactUs:"تواصل معنا",
  heading1:'العنوان الرئيسي',
  chat:"البريد الإلكتروني",
  enterHeading:"تحدث معنا",
  subHeading1:"العنوان الفرعي",
  enterSubHeading:"أدخل العنوان الفرعي",
  emailAddress:"البريد الإلكتروني",
  enterEmailAddress: "أدخل البريد الإلكتروني",
  address:" العنوان",
  office:"مكتبنا",
  enterAddress:"أدخل العنوان",
  contactNumbers:"رقم التواصل",
  follow:"متابعة",
  uploadURL:"روابط التواصل",
  discardChanges:"تجاهل التغييرات",
  save:"حفظ",
  yourChangesHaveBeenSaved:"تم حفظ التغييرات بنجاح",
  yourName:"الاسم",
  phoneNumber:'رقم الهاتف',
  enterYourMessage:"أدخل رسالتك",
  submit:"إرسال",
  enterContactNumber1:"أدخل المسؤول الأول",
  enterContactNumber2:"أدخل المسؤول الثالث",
  enterContactNumber3:"ادخل المسؤول الثاني",
  enterWebsiteURL:"أدخل رابط الموقع الإلكتروني"
}
// Customizable Area End