import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import * as Yup from 'yup';
import moment from "moment";
import { SQS } from "./ClientListingController.web";
import Strings from "../Strings";


// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
 interface SF {
  clientsId: number[],
  cityNames: string[],
  pickUpDate: string,
  custom_start_date: string,
  custom_end_date:string,
}
export interface SP {
  title :string,
  value : string
}

let initialFormValues ={
  clientsId: [],
  cityNames: [],
  pickUpDate: "",
  custom_start_date: moment().subtract(1,'days').format('DD-MM-yyyy'),
  custom_end_date: moment().format('DD-MM-yyyy'),
}

// Customizable Area End

export const webConfigJSON = require("../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  searchQueryState:SQS,
  setSearchQueryState : (arg:SQS,callApi:boolean)=>void,
  showFilterModal : ()=>void,
  companyList: any[],
  cityList: any[]
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  expanded : string | boolean
  picup_date_array : SP[]
  initialValues :SF
  switchLanguage: string
  // Customizable Area End
}
interface SS {}

export default class ClientListFilterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  formSchema: any
  formRef :  any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.formRef = React.createRef();

    this.formSchema = Yup.object().shape({
      clientsId: Yup.array().of(Yup.number()),
      cityNames: Yup.array().of(Yup.string()),
      pickUpDate : Yup.string(),
      custom_start_date : Yup
      .string()
       .when('pickUpDate', {
        is: 'custom_date',
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      custom_end_date : Yup
      .string()
       .when('pickUpDate', {
        is: 'custom_date',
        then: Yup.string().required(),
        otherwise: Yup.string(),
      })
    })

    this.state = {
      expanded : 'panel2',
      switchLanguage: '',
      picup_date_array: [
        {title : Strings.today,value:'today'},
        {title : Strings.thisWeek,value:'this_week'},
        {title : Strings.lastWeek,value:'last_week'},
        {title : Strings.lastMonth,value:'last_month'},
        {title : Strings.thisMonth,value:'this_month'},
        {title : Strings.custom,value:'custom_date'},
      ],
      initialValues:initialFormValues
    };
    // Customizable Area End
  }



  // Customizable Area Start

  async componentDidMount(){
        this.setFormValue()
        super.componentDidMount();
    const currency = await getStorageData('language');
    this.setState({
      switchLanguage:currency
    })
  }

   handleAcordionChange = (panel: string) => (event: any, isExpanded: boolean) => {
    this.setState({expanded : isExpanded ? panel : false})  
    };

    handleSubmit = (value: SF) => {
        this.props.setSearchQueryState(
          {...this.props.searchQueryState,
            clients: value.clientsId,
            citys: value.cityNames,
            custom_start_date : value.custom_start_date,
            custom_end_date : value.custom_end_date,
            pick_up_date : value.pickUpDate,
            page : 1,
            activePagiantionStep : 1
          },true)
          this.props.showFilterModal()
    }

    setPickValue=(value:string,formValues:SF,setFieldValue:any)=>()=>{
      if(value === formValues.pickUpDate){
        setFieldValue('pickUpDate', '');
      }else{
        setFieldValue('pickUpDate', value);
      }
    }

    setCustomStartDate=(formValues:SF,setFieldValue:any) =>(e:any)=>{
      let startDate = moment(e.target.value)
      let endDate = formValues.custom_end_date
      if(startDate.diff(moment(endDate,'DD-MM-yyyy'), 'days')>0){
        setFieldValue('custom_end_date',startDate.add(1, 'days').format('DD-MM-yyyy'))
      }
      setFieldValue('custom_start_date',moment(e.target.value).format('DD-MM-yyyy'))
    }

    setCustomEndDate =(setFieldValue:any) =>(e:any)=>{
      setFieldValue('custom_end_date',moment(e.target.value).format('DD-MM-yyyy'))
    }

    setFormValue = ()=>{
      let {searchQueryState} = this.props;
      this.setState({initialValues:{
        clientsId: searchQueryState.clients,
        cityNames: searchQueryState.citys,
        pickUpDate: searchQueryState.pick_up_date,
        custom_start_date: searchQueryState.custom_start_date || this.state.initialValues.custom_start_date,
        custom_end_date: searchQueryState.custom_end_date || this.state.initialValues.custom_end_date,
      }})
    }

    clearFormValue = (resetForm:any)=>()=>{
      resetForm({
        values: {...initialFormValues},
        isSubmitting: false
      })
      this.handleSubmit(initialFormValues)
    }

    cancelFilterBtn = ()=>{
      this.props.showFilterModal()
    }

    setCompanyId = (id:number,formValues:SF,setFieldValue:any)=>()=>{    
      let clientsValue= formValues.clientsId
      if(clientsValue.includes(id)){
        const index = clientsValue.indexOf(id);
        clientsValue.splice(index, 1);
        setFieldValue('clientsId',[...clientsValue])
      }else{
        setFieldValue('clientsId',[...clientsValue,id])
      }
    }


    setCityName = (city:string,formValues:SF,setFieldValue:any)=>()=>{    
      let citysValue= formValues.cityNames
      if(citysValue.includes(city)){
        const index = citysValue.indexOf(city);
        citysValue.splice(index, 1);
        setFieldValue('cityNames',[...citysValue])
      }else{
        setFieldValue('cityNames',[...citysValue,city])
      }
    }

  // Customizable Area End
}
