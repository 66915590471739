import React from "react";
import "./../../customform/src/WasteFilter.css"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Form, Formik } from "formik";
import moment from "moment";
import Strings from "./Strings";

import ServiceMaintainanceFilterController, { Props } from './ServiceMaintainanceFilterController.web'
import { calendarIcon, checkIcon, uncheckIcon } from "./assets";
import { Accordion, AccordionDetails, AccordionSummary } from "../../customform/src/WasteFilter.web";


export default class ServiceMaintainanceFilter extends ServiceMaintainanceFilterController {
    constructor(props: Props) {
        super(props);
    }

    isArabic = localStorage.getItem("language") == "arabic";

    renderStyleOnLanguage: any = (c: string) => {
        const styles: { [key: string]: any } = {
            arabicText: {
                marginRight: this.isArabic ? "0" : "23px",
                marginLeft: this.isArabic ? "23px" : "0"
            },
            checkboxArabic: {
                marginRight: this.isArabic ? "0" : "12px",
                marginLeft: this.isArabic ? "12px" : "0"
            },
            sumbitButtonArabic: {
                marginRight: this.isArabic ? "0" : "6px",
                marginLeft: this.isArabic ? "6px" : "0"
            }
        };

        return styles[c] || {};
    };

    renderDisplayDate(value: string) {
        return(
            <div style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <p className="custom_date_input_text">{value}</p>
                <img src={calendarIcon} alt="calander_icon" />
            </div>
        )
    }

    renderCustomDateView(isVisible: boolean, values: any, setFieldValue: any) {
        if (isVisible) {
            return (
                <div className="custom_date_wrapper">
                    {/* from */}
                    <div className="custom_date_input_wrapper">
                        <p className="custom_date_input_title">{Strings.from}</p>
                        {/* select from */}
                        <div className="custom_date_input_container">
                            <input 
                                data-test-id="custom-start-date"
                                className="input_date_hide"
                                value={moment(values.custom_start_date, 'DD-MM-yyyy').format('yyyy-MM-DD')}
                                onChange={this.setCustomStartDate(values, setFieldValue)}
                                max={moment().format('yyyy-MM-DD')}
                                type="date"
                            />
                            {this.renderDisplayDate(values.custom_start_date)}
                        </div>
                    </div>
                    {/* to */}
                    <div className="custom_date_input_wrapper">
                        <p className="custom_date_input_title">{Strings.to}</p>
                        {/* Select to */}
                        <div className="custom_date_input_container" >
                            <input
                                className="input_date_hide"
                                min={values.custom_start_date}
                                type="date"
                                onChange={this.setCustomEndDate(values, setFieldValue)}
                                value={moment(values.custom_end_date, 'DD-MM-yyyy').format('yyyy-MM-DD')}
                                max={moment().format('yyyy-MM-DD')}
                                data-test-id="custom-end-date"
                            />
                            {this.renderDisplayDate(values.custom_end_date)}
                        </div>
                    </div>
                </div>
            )
        } else return null
    }

    render() {
        return (
            <React.Fragment>
                <Formik
                    data-test-id="formik"
                    initialValues={this.state.initialValues}
                    enableReinitialize={true}
                    onSubmit={(values) => { this.handleSubmit(values) }}
                    validationSchema={this.formSchema}
                    innerRef={this.formRef}
                >
                    {(props) => {
                        const { values, setFieldValue, resetForm } = props;
                        return (
                            <Form translate={undefined} className="formContainer">
                                <div className="filter_modal_dashboard_wrapper">
                                    <div className="client_filter_menu_clear_wrapper">
                                        <p style={this.renderStyleOnLanguage("arabicText")} data-test-id="reset-form" onClick={this.clearFormValue(resetForm)}>{Strings.clearAll}</p>
                                    </div>
                                    <div className="client_filter_dashboard_accordian_wrapper">
                                        {/* accordian */}
                                        <Accordion data-test-id="Wasteaccordian" square expanded={this.state.expandedList === 'panel1'} onChange={this.handleExpandedList('panel1')}>
                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}>
                                                <p className={`client_accordian_header ${this.state.expandedList === 'panel1' && 'filter_accordian_active_header_color'}`}>{Strings.city}</p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="accordionDetails_wrapper">
                                                    {this.props.cityList.map((cityObj: { city: string }, comIndex: number) => {
                                                        return (
                                                            <div className="checkbbox_wrapper" key={comIndex}>
                                                                <img
                                                                    data-test-id={`city-checkbox-${comIndex}`}
                                                                    src={values.cityNames.includes(cityObj.city) ? checkIcon : uncheckIcon}
                                                                    alt="checkIcon"
                                                                    style={this.renderStyleOnLanguage("checkboxArabic")}
                                                                    className="checkbox_img"
                                                                    onClick={this.setCityNames(cityObj.city, values, setFieldValue)}
                                                                />
                                                                <p className="checkbox_title">{cityObj.city}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion data-test-id="Wasteaccordian" square expanded={this.state.expandedList === 'panel2'} onChange={this.handleExpandedList('panel2')}>
                                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}>
                                                <p className={`client_accordian_header ${this.state.expandedList === 'panel2' && 'filter_accordian_active_header_color'}`}>{Strings.timePeriod}</p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="accordionDetails_wrapper">
                                                    {this.state.date.map((pick_obj: any, pickIndex: number) => {
                                                        return (
                                                            <div data-test-id={`date${pickIndex}`} className="checkbbox_wrapper" key={pickIndex} >
                                                                <img data-test-id={`pickup-checkbox-${pickIndex}`}
                                                                    src={values.date === pick_obj.value ? checkIcon : uncheckIcon}
                                                                    alt="checkIcon" style={this.renderStyleOnLanguage("checkboxArabic")} className="checkbox_img"
                                                                    onClick={this.setDateValue(pick_obj.value, values, setFieldValue)}
                                                                />
                                                                <p className="checkbox_title">{pick_obj.title}</p>
                                                            </div>
                                                        )
                                                    })}
                                                    {/* custome date picker */}
                                                    {this.renderCustomDateView(values.date === 'custom_date', values, setFieldValue)}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                    <div className="client_filter_bottom_wraper">
                                        <button data-test-id="cancle-btn"
                                            className="client_filter_bottom_cancel_btn"
                                            onClick={(e) => {
                                                this.props.hideFilterModal()
                                                e.stopPropagation()
                                            }}
                                            >
                                            {Strings.cancel}
                                        </button>
                                        <button style={this.renderStyleOnLanguage("sumbitButtonArabic")} className="client_filter_bottom_apply_btn"
                                            type="submit">
                                            {Strings.apply}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </React.Fragment>
        );
        // Customizable Area End
    }
}
