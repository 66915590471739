import React from "react";

// Customizable Area Start
import {
    Typography,
    createTheme,
    Button,
    Box,
    Grid,
    Chip,
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "./ClientDetailsStyles.css";
import { makeStyles } from "@material-ui/core/styles"
import { fileImg, fileIcons, unChcekIcon, chcekIcon } from "./assets";
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMore';
// Customizable Area End
// Customizable Area Start
import ClientDetailsReportController, { Props } from "./ClientDetailsReportController.web";
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Strings from "./Strings";
import "./ClientReportStyles.css"
const checkedIcon = <img src={chcekIcon} />;
const icon = <img src={unChcekIcon} style={{ color: '#BFCC33' }} />;
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const customStyle = makeStyles({
    root: {
        // focused color for input with variant='outlined'
        "& .MuiOutlinedInput-root": {
            borderRadius: '8px',
            border: '1px solid #E0E2E7',
            "&.Mui-focused fieldset": {
                borderColor: "#BFCC33",
            },
        },
    },
    option: {
        "&:hover": {
            backgroundColor: "red !important"
            // backgroundColor: "#E1F0E6 !important"
        }
    }
});
const useStyles = makeStyles((theme) => ({
    customAutocomplete: {
        '& .MuiChip-root': {
            backgroundColor: '#E1F0E6',
            color: '#1C3045',
        },
    },
    '& .MuiChip-deleteIcon': {
        // backgroundColor: 'transparent', 
    },
    option: {
      "&:hover": {
        backgroundColor: "cyan !important"
      }
    }
}));
// Customizable Area End
// Customizable Area Start
const webStyle = {
    report_frqtext: {
        fontSize: '16px',
        fontFamily: 'Barlow Semi Condensed',
        fontWeight: 500,
        color: 'rgba(28, 48, 69, 0.7)',
        padding: '5px'
    },
    attachments_text: {
        fontSize: '16px',
        fontFamily: 'Barlow Semi Condensed',
        fontWeight: 600,
        color: '#1C3045',
        padding: '5px'
    },
    filename: {
        fontFamily: "Inter",
        fontSize: '16px',
        lineHeight: '24px',
    },
    filebox: {
        width: '60px',
        height: "55px",
        background: "#E1F0E6",
        borderRadius: "10px",
        position: 'relative'
    } as React.CSSProperties,
    filemg: {
        position: "absolute",
        top: '17px',
        left: ' 17px',
    } as React.CSSProperties,
}

export const getMarginPropertyByLanguage = (): 'marginRight' | 'marginLeft' => {
    const appLanguage = localStorage.getItem('language');
    return appLanguage === 'arabic' ? 'marginRight' : 'marginLeft';
};
export const getMarginPropertyButtonByLanguage = (): 'marginRight' | 'marginLeft' => {
    const appLanguage = localStorage.getItem('language');
    return appLanguage === 'arabic' ? 'marginLeft' : 'marginRight';
};
  
// Customizable Area End

export default class ClientDetailsReport extends ClientDetailsReportController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        let getOptionLabelCondition = (option:any)=>{
          return this.state.User_language === 'arabic'? option.arabic_text :option.title
        }
        const marginPrpty = getMarginPropertyByLanguage();
        const marginButton=getMarginPropertyButtonByLanguage();
        return (
            // Customizable Area Start
            // main container
            <Grid container style={{ [marginPrpty]: '33px', backgroundColor: 'rgba(245, 245, 245, 1)', paddingBottom: '10px' }} onDrop={this.handleDrop}
                onDragOver={this.handleDragOver}>

                <Grid item lg={8} md={8} sm={8}>
                    <div>
                        <Typography style={webStyle.report_frqtext}>{Strings.reportFrequency}</Typography>
                        <Autocomplete
                            options={this.state.reportFreq}
                            getOptionLabel={(option) => getOptionLabelCondition(option)}
                            fullWidth
                            value={this.state.report_frequency}
                            data-test-id="repAuto"
                           
                            onChange={this.handleChangeReport}
                            open={this.state.isAutocompleteOpen}
                            classes={{option:"report-dropdown-options"}}
                            onOpen={this.handleAutocompleteOpen}
                            onClose={this.handleAutocompleteClose}
                            renderInput={(params) => (
                                <TextField
                                    data-test-id="reportInput"
                                    {...params}
                                    placeholder={Strings.bianually}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: false }}
                                    className={customStyle().root}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontFamily: 'Barlow Semi Condensed' },
                                        endAdornment: (
                                            <div
                                                style={{ cursor: 'pointer', marginRight: '-50px' }}
                                                onClick={this.handleIconClick}
                                            >
                                                {this.state.isAutocompleteOpen ? (
                                                    <ExpandLessRoundedIcon />
                                                ) : (
                                                    <ExpandMoreRoundedIcon style={{ color: '#94A3B8' }} />
                                                )}
                                            </div>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div >
                        <Typography style={webStyle.report_frqtext}>{Strings.wasteTypeCatagory}</Typography>
                        <Autocomplete
                            multiple
                            value={this.state.waste_type_category_ids}
                            id="checkboxes-tags-demo"
                            data-test-id="wasteAuto"
                            classes={{option:"report-dropdown-options"}}
                            options={this.state.wasteCategory || []}
                            disableCloseOnSelect
                            fullWidth
                            onChange={this.handleWasteTypeCategoriesChange}
                            getOptionLabel={(option: any) => (option && option.attributes && option.attributes.name) || ''}

                            open={this.state.isAutocompleteOpenWaste}
                            onOpen={this.handleAutocompleteOpenWaste}
                            onClose={this.handleAutocompleteCloseWaste}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        data-test-id='checkBtn'
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option?.attributes?.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField {...params}
                                        data-test-id="wasteInput"
                                        variant="outlined"
                                        placeholder=""
                                        InputLabelProps={{ shrink: false }}
                                        className={`${customStyle().root} ${useStyles().customAutocomplete}`}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <div
                                                    style={{ cursor: 'pointer', marginRight: '-50px' }}
                                                    onClick={this.handleIconClickWaste}
                                                >
                                                    {this.state.isAutocompleteOpenWaste ? (
                                                        <ExpandLessRoundedIcon />
                                                    ) : (
                                                        <ExpandMoreRoundedIcon style={{ color: '#94A3B8' }} />
                                                    )}
                                                </div>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option?.attributes?.name}
                                        {...getTagProps({ index })}
                                        deleteIcon={<CloseIcon style={{ color: 'black', width: '15px', height: '15px',paddingLeft:'15px'}} />}
                                    />
                                ))
                            }
                        />
                    </div>
                    <div>
                        <Typography style={webStyle.report_frqtext}>{Strings.awarenessGiven}</Typography>
                        <Autocomplete
                            fullWidth
                            multiple
                            value={this.state.awareness_given}
                            id="checkboxes-tags-demo"
                            data-test-id="awareAuto"
                            options={this.state.defaultOptionsawarnessGiven}
                            disableCloseOnSelect
                            classes={{option:"report-dropdown-options"}}
                            onChange={this.handleAwarnessChange}
                            open={this.state.isAutocompleteOpenAwar}
                            onOpen={this.handleAutocompleteOpenAwar}
                            onClose={this.handleAutocompleteCloseAwar}
                            getOptionLabel={(option) => getOptionLabelCondition(option)}
                            renderOption={(option, { selected }) =>  (
                                <React.Fragment>
                                    <Checkbox
                                        data-test-id="checkBoxBtn"
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={option.selected}
                                    />
                                    {getOptionLabelCondition(option) }
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params}
                                    data-test-id="AwarnessInput"
                                    variant="outlined"
                                    placeholder=""
                                    InputLabelProps={{ shrink: false }}
                                    className={`${customStyle().root} ${useStyles().customAutocomplete}`}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <div
                                                style={{ cursor: 'pointer', marginRight: '-50px' }}
                                                onClick={this.handleIconClickAwar}
                                            >
                                                {this.state.isAutocompleteOpenAwar ? (
                                                    <ExpandLessRoundedIcon />
                                                ) : (
                                                    <ExpandMoreRoundedIcon style={{ color: '#94A3B8' }} />
                                                )}
                                            </div>
                                        ),
                                    }}

                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={getOptionLabelCondition(option)}
                                        {...getTagProps({ index })}
                                        deleteIcon={<CloseIcon style={{ color: 'black', width: '15px', height: '15px' ,paddingLeft:'15px'}} />}
                                    />
                                ))
                            }
                        />
                    </div>
                    <div>
                        <Typography style={webStyle.attachments_text}>{Strings.attachments}</Typography>
                        <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            style={{ display: 'none', }}
                            onChange={this.handleFileSelect}
                            multiple
                        />
                        <label htmlFor="fileInput">
                            <Box
                                onDrop={this.handleDrop}
                                onDragOver={this.handleDragOver}
                                style={{
                                    padding: '20px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    border: '1px dashed #ccc',
                                    height: '240px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '8px',
                                    borderColor: '#32569A',
                                    backgroundColor: 'white',
                                }}
                            >

                                <Box>
                                    <Box style={{ display: 'flex' }}>
                                        <img width='24px' height='24px' style={{ marginRight: '10px' }} src={fileImg} />
                                        <Typography
                                            style={{
                                                fontFamily: 'Barlow Semi Condensed',
                                                fontWeight: 600,
                                                fontSize: '16px',
                                                color: '#1C3045',
                                            }}>
                                            {Strings.dragAndDropFile}
                                            <span style={{
                                                fontFamily: 'Barlow Semi Condensed',
                                                fontWeight: 600,
                                                fontSize: '16px',
                                                color: '#32569A',
                                                marginLeft: '4px'
                                            }}>{Strings.browse}</span>
                                        </Typography>
                                    </Box>
                                    <Typography style={{
                                        fontFamily: 'Barlow Semi Condensed',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        color: '#1C3045',
                                    }}>{Strings.maxFileSize}</Typography>
                                </Box>

                            </Box>

                        </label>


                        <Box>
                            {Array.isArray(this.state.selectedFile) ? (
                                <>
                                    {this.state.selectedFile?.map((file: any, index: number) => {
                                        return (
                                            <Box key={index} boxShadow={2} mt={2} p={1} borderRadius={3} style={{ background: "#fff", display: 'flex', gap: "10px", alignItems: 'center' }}>
                                                <Box style={webStyle.filebox}>
                                                    <img src={fileIcons} alt="" style={webStyle.filemg} />
                                                </Box>
                                                <Box style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                                                    <Typography variant="h6" style={webStyle.filename}>{file.name}</Typography>
                                                    <CloseIcon onClick={(index: any) => this.handlefileClose(index, file)} style={{ cursor: 'pointer' }} />
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </>
                            ) : (null)
                            }
                        </Box>
                    </div>
                </Grid>
                <div className="bottom-button-container" style={{[marginButton]: "2.5rem"}}>
                <button
                    data-test-id='discardBtn'
                    className="add-button"
                    onClick={this.handleDiscard}
                >
                    <p className="add-button-text">{Strings.discardChanges}</p>
                </button>
                <button
                    data-test-id="saveBtn"
                    className="add-button button-fill"
                    onClick={this.report}
                    disabled={this.state.submitButtonDisable}
                >
                    <p className="add-button-text">{Strings.save}</p>
                </button>
                </div>
            </Grid>
            // Customizable Area End
        );
    }
}
