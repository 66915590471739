import React from 'react'
import ContractDetailsController from "./ContractDetailsController";
import './ClientDetailsStyles.css';
import './ContractStyless.css';
import { Box } from '@material-ui/core';
import { addIcon, arrow, calendar, deleteIcon, plusIcon, tick } from './assets';
import Select from 'react-select';
import Strings from './Strings';
import moment from 'moment';



export default class ContractDetails extends ContractDetailsController {

    counter = 0;

    freqOfPickupSection() {
        return (
        <div className="control-row" >
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }} >
                <label className="label-text">{Strings.startDate}</label>
                <div className='right-control-col'>
                    <span className="datepicker-box-input">
                        <input type="date" data-testid="pickup-date" className="date-opacity text-field-input" value={moment(this.state.pickupDate, 'DD-MM-yyyy').format('yyyy-MM-DD')} onChange={(event) => this.handlePickupDate(event)} />
                        <p className='date-text'>{this.state.pickupDate ? this.state.pickupDate : Strings.selectADates}</p>
                        <span><img src={calendar}></img></span>
                    </span>
                </div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <label className="label-text">{Strings.frequency}</label>
                <div className='right-control-col'>
                    <span className="datepicker-box-input pd">
                        <Select placeholder={Strings.select} data-testid="pickup-freq" options={this.optionsBottom} styles={this.myStyle} style={{ border: '0px', Width: '18rem' }} value={this.state.pickupFrequency} onChange={(event) => this.handlePickupFrequency(event)}></Select>
                        <span className='adornment ad-align'><img src={arrow} style={{ marginLeft: '17px' }}></img></span>
                    </span>
                    <span className='label-text' style={{ visibility: 'hidden' }}>{Strings.occurences}</span>
                </div>
            </Box>
        </div>)
    }

    section() {
        return (
            <div className="control-row">
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }} >
                    <label className="label-text">{Strings.frequency}</label>
                    <span className="datepicker-box-input pd">
                        <Select placeholder={Strings.select} options={this.options} data-testid="payment-frequency" styles={this.myStyle} style={{ border: '0px', Width: '18rem' }} value={this.state.frequency} onChange={(event) => this.handleFrequencySelect(event)}></Select>
                        <span className="adornment ad-align"><img src={arrow} style={{ marginLeft: '17px' }}></img></span>
                    </span>
                </Box>


                <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                    <label className="label-text">{Strings.firstPickupDate}</label>
                    <div className='right-control-col'>
                        <span className="datepicker-box-input">
                            <input type="date" className="date-opacity text-field-input" data-testid="payment-date" value={moment(this.state.firsPickupDate, 'DD-MM-yyyy').format('yyyy-MM-DD')} onChange={(event) => this.firstPickupHandle(event)} />
                            <p className='date-text'>{this.state.firsPickupDate ? this.state.firsPickupDate : Strings.selectADates}</p>
                            <span ><img src={calendar} ></img></span>
                        </span>

                        <span className='label-text' style={{ visibility: 'hidden' }}>{Strings.occurences}</span>
                    </div>
                </Box>
            </div>)
    }

    getDatesRow(item: any, index: number) {
        if (item.isSaved || item.isDbSaved) {
            return <div className="control-row" key={index}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                    <label className="label-text">{Strings.startDate}</label>
                    <span className="datepicker-box">
                        <input type="date" data-testid="saved-contract-start-date" max={moment(item.end_date, 'DD-MM-yyyy').format('yyyy-MM-DD') ? moment(item.end_date, 'DD-MM-yyyy').format('yyyy-MM-DD') : ''} className="date-opacity text-field" value={moment(item.start_date, 'DD-MM-yyyy').format('yyyy-MM-DD')} onChange={(event) => this.startDateHandle('start', item, event)} />
                        <p className='date-text bg-color'>{item.start_date ? item.start_date : Strings.selectADates}</p>
                        <span>
                            <img src={calendar} />
                        </span>

                    </span>
                </Box>


                <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                    <label className="label-text">{Strings.endDate}</label>
                    <div className='right-control-col'>
                        <span className="datepicker-box">
                            <input type="date" data-testid="saved-contract-end-date" className="date-opacity text-field" min={moment(item.start_date, 'DD-MM-yyyy').format('yyyy-MM-DD') ? moment(item.start_date, 'DD-MM-yyyy').format('yyyy-MM-DD') : ''} onChange={(event) => this.startDateHandle('end', item, event)} value={moment(item.end_date, 'DD-MM-yyyy').format('yyyy-MM-DD')} />
                            <p className='date-text bg-color'>{item.end_date ? item.end_date : Strings.selectADates}</p>
                            <span ><img src={calendar}></img></span>
                        </span>
                        <span data-testid={`saved-btn-${index}`} className="contract-span" onClick={() => this.deleteRow(item)} style={{ minWidth: '76px !important' }}><img src={deleteIcon}></img></span>
                    </div>
                </Box>
            </div>
        }
        else {
            return this.getContractSimpleRow(item, index)
        }
    }

    getContractSimpleRow(item: any, index: number) {

        return <div className="control-row" key={index}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                <label className="label-text">{Strings.startDate}</label>
                <span className="datepicker-box-input">
                    <input type="date" data-testid="contract-date-start-input" max={moment(item.end_date, 'DD-MM-yyyy').format('yyyy-MM-DD') ? moment(item.end_date, 'DD-MM-yyyy').format('yyyy-MM-DD') : ''} className="date-opacity text-field-input" value={moment(item.start_date, 'DD-MM-yyyy').format('yyyy-MM-DD')} onChange={(event) => this.startDateHandle('start', item, event)} />
                    <p className='date-text'>{item.start_date ? item.start_date : Strings.selectADates}</p>
                    <span className="adornment"><img src={calendar}></img></span>
                </span>
            </Box>


            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                <label className="label-text">{Strings.endDate}</label>
                <div className='right-control-col'>
                    <span className="datepicker-box-input">
                        <input type="date" data-testid="contract-date-end-input" min={moment(item.start_date, 'DD-MM-yyyy').format('yyyy-MM-DD') ? moment(item.start_date, 'DD-MM-yyyy').format('yyyy-MM-DD') : ''} className=" date-opacity text-field-input" value={moment(item.end_date, 'DD-MM-yyyy').format('yyyy-MM-DD')} onChange={(event) => this.startDateHandle('end', item, event)} />
                        <p className='date-text'>{item.end_date ? item.end_date : Strings.selectADates}</p>
                        <span className="adornment"><img src={calendar}></img></span>
                    </span>
                    <span data-testid={`delete-btn-${index}`} className="contract-span" onClick={() => this.deleteRow(item)}><img src={deleteIcon}></img></span>
                </div>
            </Box>

        </div>
    }

    optionsFreq = [{ label: Strings.week, value: "week" },
    { label: Strings.month, value: "Month" },
    { label: Strings.year, value: 'Year' },
    ]

    repeatOptions = [{ label: Strings.monday, value: "Monday" },
    { label: Strings.tuesday, value: "Tuesday" },
    { label: Strings.wednesday, value: 'Wednesday' },
    { label: Strings.thursday, value: 'Thursday' },
    { label: Strings.friday, value: 'Friday' },
    { label: Strings.saturday, value: 'Saturday' },
    { label: Strings.sunday, value: 'Sunday' },
    ]

    optionsBottom = [{ label: Strings.daily, value: "Daily" },
    { label: Strings.weekly, value: "Weekly" },
    { label: Strings.monthly, value: 'Monthly' },
    ]


    options = [{ label: Strings.monthly, value: "Monthly" },
    { label: Strings.quarterly, value: "Quarterly" },
    { label: Strings.biannual, value: 'Bi-Annual' },
    ]

    myStyle = {
        option: () => ({
            padding: '0.56rem 1rem 0.56rem 1rem', // Add padding as desired
            color: 'black', // Change text color based on selection
            backgroundColor: 'white', // Change background color based on selection
            fontFamily: 'Barlow Semi Condensed',
            width: 'inherit',
            borderRadius: '0.5rem',
            fontSize: '0.875rem',
            '&:hover': {
                backgroundColor: '#E1F0E6', // Change the background color on hover
            },
        }),
        control: (provided: any) => ({
            ...provided,
            border: 'none', // Remove the border
            '&.react-select__dropdown-indicator': {
                display: 'none', // Hide the dropdown indicator
            },

            fontFamily: 'Barlow Semi Condensed',
            fontSize: '0.875rem',
            width: 'inherit',
            outline: 'none',
            fontWeight: 400,
            background: '#F9F9FC'
        }),

    }

    getDueDateRow(item: any, index: number) {

        return <>
            <div className="control-row" key={index}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                    <label className="label-text">{Strings.dueDate}</label>
                    <div className='right-control-col'>
                        <span className="datepicker-box-input">
                            <input type="date" className="date-opacity text-field-input" data-testid="due-date" value={moment(item.dueDate, 'DD-MM-yyyy').format('yyyy-MM-DD')} onChange={(event) => this.dueDateHandle(event, item)} />
                            <p className='date-text'>{item.due_date ? item.due_date : Strings.selectADates}</p>
                            <span ><img src={calendar}></img></span>
                        </span>

                        <span data-testid={`duedate-dlt-${index}`} onClick={() => this.deleteRowDueDate(item)}><img src={deleteIcon}></img></span>
                    </div>
                </Box>
            </div>

            <div className='heading'>
                <Box sx={{ display: 'flex', alignItems: 'center', gridGap: '1rem' }}>
                    <label className="container-check">
                        <input type="checkbox" checked={item.recurring_payment} data-testid="recurring-payment" onChange={(event) => this.recurringCheckbox(event, item)} />
                        {item.recurring_payment ? <span className='checkbox-custom'><img src={tick} alt="Your Image"></img> </span> : <span className="checkmark"></span>}

                    </label>
                    <span>{Strings.recurringPayment}</span>
                </Box>
            </div>

            {item.recurring_payment ?

                <section className="date-container">

                    <div className="control-row">
                        <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                            <label className="label-text">{Strings.repeakEvery}</label>
                            <span className="datepicker-box-input">
                                <input type="text" className="text-field-input" data-testid="repeat-every" placeholder={Strings.number} value={item.recurring_day} onChange={(event) => this.repeatEveryHandle(event, item)} />

                            </span>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                            <label className="label-text" style={{ visibility: 'hidden' }}>label</label>
                            <div className='right-control-col'>
                                <span className="datepicker-box-input pd" style={{ width: '18rem' }}>
                                    <Select options={this.optionsFreq} styles={this.myStyle}
                                        placeholder={Strings.select} data-testid="repeat-tenure" style={{ border: '0px', Width: '18rem' }} value={item.recurring_tenure_drop_down} onChange={(event) => this.frequencyHandle(event, item)}></Select>
                                    <span className="adornment ad-align"><img src={arrow} style={{ marginLeft: '17px' }}></img></span>
                                </span>
                                <span className='label-text' style={{ visibility: 'hidden' }}>{Strings.occurences}</span>
                            </div>
                        </Box>
                    </div>
                    <div className='control-row'>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                            <label className="label-text" >{Strings.repeatOn}</label>
                            <div className='right-control-col'>
                                <span className="datepicker-box-input pd" style={{ width: '18rem' }}>
                                    <Select placeholder={Strings.select} options={this.repeatOptions} styles={this.myStyle} data-testid="repeat-tenure" style={{ border: '0px', Width: '18rem' }} value={item.recurring_on_drop_down} onChange={(event) => this.repeatOnHandle(event, item)}></Select>
                                    <span className="adornment ad-align"><img src={arrow} style={{ marginLeft: '17px' }}></img></span>
                                </span>
                                <span className='label-text' style={{ visibility: 'hidden' }}>{Strings.occurences}</span>
                            </div>
                        </Box>
                    </div>



                    <div className='control-row'>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                            <label className="label-text">{Strings.ends}</label>
                            <div className="control-box">
                                <span className="">
                                    <label className="container-radio">
                                        <input type="radio" name={`radio-${index}`} checked={item.recurring_ends_on} data-testid="ends-on" onChange={(event) => this.handleOnRadio(event, item)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </span>
                                <span className='control-text'>{Strings.on}</span>
                            </div>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                            <div className='right-control-col'>
                                <span className="datepicker-box-input">
                                    <input type="date" data-testid="end-date" className={`date-opacity text-field-input ${item.recurring_ends_on ? '' : 'disable'}`} value={moment(item.recurring_end_date, 'DD-MM-yyyy').format('yyyy-MM-DD')} onChange={(event) => this.endsOnDateHandle(event, item)} />
                                    <p className='date-text'>{item.recurring_end_date ? item.recurring_end_date : Strings.selectADates}</p>
                                    <span><img src={calendar}></img></span>
                                </span>

                                <span className='label-text' style={{ visibility: 'hidden' }}>{Strings.occurences}</span>
                            </div>
                        </Box>
                    </div>


                    <div className='control-row'>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                            <div className="control-box">
                                <span className=""><label className="container-radio">
                                    <input type="radio" data-testid="end-after" name={`radio-${index}`} checked={item.recurring_ends_after} onChange={(event) => this.afterRadioHandle(event, item)} />
                                    <span className="checkmark"></span>
                                </label>
                                </span>
                                <span className='control-text'>{Strings.after}</span>
                            </div>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }} >

                            <div className='right-control-col-occ'>
                                <span className="datepicker-box-input">
                                    <input type="text" data-testid="occurence" className={`text-field-input ${item.recurring_ends_after ? '' : 'disable'}`} placeholder={Strings.number} value={item.recurring_occurrence ? item.recurring_occurrence : ''} onChange={(event) => this.occurenceHandle(event, item)} />

                                </span>
                                <span className='label-text'>{Strings.occurences}</span>
                            </div>
                        </Box>
                    </div>
                </section>

                :

                null}
        </>
    }

    render() {

        return (
            <div className="main-container">
                <div className="contract-container">
                    <div className="heading">
                        {Strings.currentContractDetails}
                    </div>
                    <section className="date-container" key={'contract-date'}>
                        {this.state.contractDates.map((item: any, index: number) => (
                            this.getDatesRow(item, index)

                        ))}
                        <button
                            style={{ marginTop: '0.75rem', marginBottom: 0 }}
                            className="add-button"
                            type="submit"
                            onClick={this.addRow}
                            data-testid="add-contractdates"
                        >
                            <img src={addIcon} className="icon" />
                            <p className="add-button-text">{Strings.addNewContract}</p>
                        </button>




                    </section>

                    <div className="heading">
                        {Strings.paymentTerms}
                    </div>
                    <section className='date-container'>
                        {this.section()}
                    </section>

                    <div className="heading">
                        {Strings.dueDatePayment}
                    </div>

                    <section className="date-container" key={'due-date'}>

                        {this.state.dueDatesList.map((row: any, index: number) => (

                            this.getDueDateRow(row, index)

                        ))

                        }










                    </section>

                    <button
                        style={{ marginTop: '1.5rem', marginBottom: 0 }}
                        className="add-button"
                        onClick={() => this.addDueDateRow()}
                        data-testid="add-due"
                        type="submit"
                    >
                        <img src={addIcon} className="icon" />
                        <p className="add-button-text">{Strings.addAdditionalDueDates}</p>
                    </button>

                    <div className="heading">
                        {Strings.frequencyofPickup}
                    </div>

                    <section className="date-container" key={'pickup'}>

                        {this.freqOfPickupSection()}

                    </section>

                    <div className="heading">
                        {Strings.numberofRecyleBins}
                    </div>

                    <section className='date-container' key={'recycle'}>
                        <div className='control-row'>
                            <div className='control-box'>
                                <span className="label-text">{Strings.indoorRecycleBins}</span>
                            </div>

                            <div className='control-box'>
                                <span className='inner-container'>
                                    <button className="inc-dec-btn" data-testid="inc-btn" onClick={() => this.handleRecycle()}><img src={plusIcon}></img></button> <span>{this.state.inCount}</span> <button data-testid="dec-btn" className={`inc-dec-btn ${this.state.inCount < 1 ? 'dec-disable' : ''}`} onClick={() => this.handleRecycleDec()}>-</button>
                                </span>
                            </div>


                        </div>

                        <div className='control-row'>
                            <div className='control-box'>
                                <span className="label-text">{Strings.outdoorRecycleBins}</span>
                            </div>

                            <div className='control-box'>
                                <span className='inner-container'>
                                    <button className='inc-dec-btn' data-testid="inc-btno" onClick={() => this.handleRecycleOut()}><img src={plusIcon} ></img></button> <span>{this.state.outCount}</span> <button onClick={() => this.handleRecycleOutDec()} data-testid="dec-btno" className={`inc-dec-btn ${this.state.outCount < 1 ? 'dec-disable' : ''}`}>-</button>
                                </span>
                            </div>


                        </div>
                    </section>








                </div>


                <div className='bottom-box'>

                </div>

                <div className="bottom-button-container">
                    <button
                        data-testid={"discard-button"}
                        onClick={() => { this.discard() }}
                        className="add-button"
                    >
                        <p className="add-button-text">{Strings.discardChanges}</p>
                    </button>
                    <button
                        data-testid={"saveButton"}
                        onClick={() => this.save()}
                        className={`add-button button-fill ${this.getDisableState() ? "disable" : ""}`}
                    >
                        <p className="add-button-text">{Strings.save}</p>
                    </button>
                </div>

            </div>

        )

    }

}