import React from "react";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import "./PickupRequest.css"
import PickupRequestController, { Props } from "./PickupRequestController.web";
import { Grid, withStyles, TextField, Accordion, AccordionSummary, AccordionDetails, Modal } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { arrowRightIcon, calendar, caretLeft, caretRight, checkIcon, checkRadioIcon, checkbox, crossIcon, filter, minusIcon, moreIcon, noResult, sort, tickCircle, trash, uncheckIcon, close } from "./assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Strings from './Strings'



export default class PickupRequest extends PickupRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  pickupRequestStatusTabs(label: string, index: number) {
    return (
      <div key={index} data-test-id={`statusTab${index}`} onClick={() => this.onChangeScreen(index)}>
        <p className={this.state.activeTabIndex === index ? "status-tab-active" : "status-tab"}>
          {label}
        </p>
        {
          this.state.activeTabIndex === index ?
            <div
              style={{
                width: "100%",
                height: "2px",
                borderRadius: "2px",
                backgroundColor: "#BFCC33",
              }}
            /> : null
        }
      </div>
    )
  }

  getFilterModal() {
    return (
      <div
        style={this.renderStyleOnLanguage("filterModalArabic")}
        className="pickup-request-filter-menu"
        ref={this.filterModalRef}
        data-test-id="filterModal"
      >
        <div className="pickup-request-filter-menu-header" data-test-id="filterHeader">
          <p
            className="pickup-request-filter-menu-header-text"
            data-test-id="clearFilterBtn"
            onClick={this.clearFilters}
          >
            {Strings.clearAll}
          </p>
        </div>
        <div data-test-id="filterPanels" className="pickup-request-filter-accordion-wrapper">
          <Accordion
            expanded={this.state.expanded === "panel1"}
            square
            onChange={this.handleAccordianChange("panel1")}
            elevation={0}
            style={{ margin: "0", width: "15.5rem" }}
            data-test-id="clientsFilter"
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#8D97A2" }} />}
            >
              <p
                className={`pickup-request-filter-menu-header-text ${this.state.expanded === "panel1" && "active-header"}`}
              >
                {Strings.clients}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordian-details-wrapper">
                {
                  this.state.clients.map((client: any, index: number) => {
                    return (
                      <div className="pickup-request-filter-menu-item-container" key={index}>
                        <img
                          src={this.isClientSelected(client.id) ? checkIcon : uncheckIcon}
                          alt="uncheckIcon"
                          className="pickup-request-menu-item-img"
                          onClick={() => this.onSelectClient(client.id)}
                          data-test-id={`clientFilter${index}`}
                        />
                        <p className="pickup-request-menu-item-text">{client.name}</p>
                      </div>
                    );
                  })
                }
              </div>
            </AccordionDetails>
          </Accordion>
          {this.state.expanded === "panel2" && <div className="filter-seperator"></div>}
          <Accordion
            expanded={this.state.expanded === "panel2"}
            square
            onChange={this.handleAccordianChange("panel2")}
            elevation={0}
            style={{ margin: "0", width: "15.5rem" }}
            data-test-id="pickupDateFilter"
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#8D97A2" }} />}
            >
              <p
                className={`pickup-request-filter-menu-header-text ${this.state.expanded === "panel2" && "active-header"}`}
              >
                {Strings.pickupdate}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordian-details-wrapper">
                {
                  this.state.pickupDates.map((pickupDate: any, index: number) => {
                    return (
                      <div className="pickup-request-filter-menu-item-container" key={index}>
                        <img
                          src={this.isPickupDateSelected(pickupDate.value) ? checkIcon : uncheckIcon}
                          alt="uncheckIcon"
                          className="pickup-request-menu-item-img"
                          onClick={() => this.onSelectPickupDate(pickupDate.value)}
                          data-test-id={`pickupDateFilter${index}`}
                        />
                        <p className="pickup-request-menu-item-text">{pickupDate.title}</p>
                      </div>
                    );
                  })
                }
              </div>
            </AccordionDetails>
          </Accordion>
          {this.state.expanded === "panel3" && <div className="filter-seperator"></div>}
          <Accordion
            expanded={this.state.expanded === "panel3"}
            square
            onChange={this.handleAccordianChange("panel3")}
            elevation={0}
            style={{ margin: "0", width: "15.5rem" }}
            data-test-id="branchesFilter"
          >
            <AccordionSummary
              aria-controls="panel3a-content"
              id="panel3a-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#8D97A2" }} />}
            >
              <p
                className={`pickup-request-filter-menu-header-text ${this.state.expanded === "panel3" && "active-header"}`}
              >
                {Strings.branch}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordian-details-wrapper">
                {
                  this.state.branches.map((branch: any, index: number) => {
                    return (
                      <div className="pickup-request-filter-menu-item-container" key={index}>
                        <img
                          src={this.isBranchSelected(branch.name) ? checkIcon : uncheckIcon}
                          alt="uncheckIcon"
                          className="pickup-request-menu-item-img"
                          onClick={() => this.onSelectBranch(branch.name)}
                          data-test-id={`branchFilter${index}`}
                        />
                        <p className="pickup-request-menu-item-text">{branch.name}</p>
                      </div>
                    );
                  })
                }
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="filter-seperator"></div>
        </div>
      </div>
    );
  }

  getSortModal() {
    return (
      <div
        style={this.renderStyleOnLanguage("sortMenuRightLeft")}
        className="pickup-request-sort-menu"
        ref={this.sortModalRef}
        data-test-id="sortModal"
      >
        <div className="pickup-request-sort-menu-header">
          <p className="pickup-request-sort-menu-header-text">{Strings.sort}</p>
        </div>
        {
          this.state.sortList.map((item: any, index: number) => {
            return (
              <div className="pickup-request-sort-menu-item" key={index}>
                {
                  item.desc === this.state.searchQuery.sort ? (
                    <div
                      className="pickup-request-sort-menu-item-img-container"
                      data-test-id={`sortItem${index}`}
                      onClick={() => this.onSortItemClick(item.desc)}
                    >
                      <img src={checkRadioIcon} alt="checked" className="pickup-request-sort-menu-item-tick-img" />
                    </div>
                  ) : (
                    <div
                      className="pickup-request-sort-menu-item-img-container"
                      data-test-id={`sortItem${index}`}
                      onClick={() => this.onSortItemClick(item.desc)}
                    >
                    </div>
                  )
                }
                <div className="pickup-request-sort-menu-item-text-container">
                  <p className="pickup-request-sort-menu-item-text">{item.before}</p>
                  {item.after !== "" && (
                    <img
                      src={arrowRightIcon}
                      alt="rightArrow"
                      className="pickup-request-sort-menu-item-arrow"
                    />
                  )}
                  {item.after !== "" && (
                    <p className="pickup-request-sort-menu-item-text">{item.after}</p>
                  )}
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

  getPages() {
    let numbers = [];
    let currentPage = this.state.searchQuery.page;
    for (
      let i = this.state.pageBatch * 5 - 4;
      i <=
      (this.state.totalPages > this.state.pageBatch * 5
        ? this.state.pageBatch * 5
        : this.state.totalPages);
      i++
    )
      numbers.push(i);
    return (
      <div className="pickup-request-list-page-container">
        <div
          className="pickup-request-list-page-inner-container"
          title="Previous Page"
          onClick={this.onPreviousPageClick}
          data-test-id="previousPage"
        >
          <img
            src={caretLeft}
            alt="Previous Page"
            className="pickup-request-list-page-icon"
          />
        </div>
        {
          numbers.map((item: number, index: number) => {
            return (
              <div
                title={`Page ${item}`}
                data-test-id={`pageButton${index}`}
                className={`pickup-request-list-page-inner-container ${currentPage === item
                  ? "pickup-request-list-page-inner-container-selected" : ""
                  }`}
                onClick={() => this.onPageClick(item)}
                key={index}
              >
                <p
                  className={`pickup-request-list-page-number-text ${currentPage === item
                    ? "pickup-request-list-page-number-text-selected" : ""
                    }`}
                >
                  {item}
                </p>
              </div>
            );
          })
        }
        {
          this.state.pageBatch < Math.ceil(this.state.totalPages / 5) && (
            <div className="pickup-request-list-page-inner-container">
              <p className="pickup-request-list-page-number-text">{"..."}</p>
            </div>
          )
        }
        <div
          title="Next Page"
          className="pickup-request-list-page-inner-container"
          onClick={this.onNextPageClick}
          data-test-id="nextPage"
        >
          <img src={caretRight} alt="Next Page" className="pickup-request-list-page-icon" />
        </div>
      </div>
    );
  }

  getPaginationCaption() {
    return (
      <p style={this.renderStyleOnLanguage("footertext")} className="pickup-request-table-footer-text">{`${Strings.showing} ${(this.state.searchQuery.page - 1) * 10 + 1
        } - ${(this.state.searchQuery.page - 1) * 10 + 1 + 10 > this.state.totalItems
          ? (this.state.searchQuery.page - 1) * 10 +
          1 +
          (this.state.totalItems - ((this.state.searchQuery.page - 1) * 10 + 1))
          : (this.state.searchQuery.page - 1) * 10 + 10
        } ${Strings.From} ${this.state.totalItems}`}</p>
    );
  }

  getActionMenu(requestId: string, clientName: string, pickUpDate: string) {
    return (
      <div className="pickup-request-action-menu" style={{...this.renderStyleOnLanguage("left"),...this.renderStyleOnLanguage("marginLeft")}} ref={this.actionsModalRef} data-test-id="actionMenu">
        <p
          className="pickup-request-action-menu-item accept-action"
          data-test-id="acceptAction"
          onClick={() => {
            this.acceptPickupRequest(requestId);
          }}
        >
          {Strings.accept}
        </p>
        <p
          className="pickup-request-action-menu-item decline-action"
          data-test-id="declineAction"
          onClick={() => {
            this.openDeclineModal(requestId, clientName);
          }}
        >
          {Strings.decline}
        </p>
        <p
          className="pickup-request-action-menu-item reschedule-action"
          data-test-id="rescheduleAction"
          onClick={() => {
            this.openRescheduleModal(requestId, clientName, pickUpDate);
          }}
        >
          {Strings.reschedule}
        </p>
      </div>
    );
  }

  getDeclineModal() {
    return (
      <Modal
        open={this.state.showDeclineModal}
        onClose={this.closeDeclineModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="declineModal"
      >
        <div className="pickup-request-action-modal">
          <div className="pickup-request-action-modal-title">
            <p className="pickup-request-action-modal-title-text">{Strings.declineWarning}</p>
            <img
              src={crossIcon}
              alt="Close"
              className="pickup-request-action-modal-close-btn"
              onClick={this.closeDeclineModal}
              data-test-id="declineModalCloseBtn"
            />
          </div>
          <div className="pickup-request-action-modal-content">
            <div className="pickup-request-action-modal-content-field">
              <p className="pickup-request-action-modal-content-field-label">{Strings.clientName}</p>
              <input
                type="text"
                className="pickup-request-action-modal-content-field-input disabled-input"
                value={this.state.selectedPickup.clientName}
                disabled
              />
            </div>
            <div className="pickup-request-action-modal-content-field">
              <p
                className="pickup-request-action-modal-content-field-label"
              >
                {Strings.reasonForDecline}
              </p>
              <textarea
                placeholder="Enter your comment"
                className="pickup-request-action-modal-content-field-comments"
                onChange={this.handleReasonChange}
                data-test-id="declineReason"
              >
              </textarea>
            </div>
          </div>
          <div className="pickup-request-action-modal-buttons">
            <button
              className="pickup-request-action-button pickup-request-decline-all-button"
              data-test-id="declineBtn"
              onClick={() => {
                this.declinePickupRequest(this.state.selectedPickup.requestId);
                this.closeDeclineModal();
              }}
            >
              <p className="pickup-request-button-text pickup-request-action-button-text">{Strings.decline}</p>
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  getDeclineAllModal() {
    return (
      <Modal
        open={this.state.showDeclineAllModal}
        onClose={this.closeDeclineAllModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="declineAllModal"
      >
        <div className="pickup-request-action-modal">
          <div className="pickup-request-action-modal-title">
            <p className="pickup-request-action-modal-title-text">{Strings.declineAllWarning}</p>
            <img
              src={crossIcon}
              alt="Close"
              className="pickup-request-action-modal-close-btn"
              onClick={this.closeDeclineAllModal}
              data-test-id="declineAllModalCloseBtn"
            />
          </div>
          <div className="pickup-request-action-modal-content">
            <div className="pickup-request-action-modal-content-field">
              <p className="pickup-request-action-modal-content-field-label">{Strings.reasonForDecline}</p>
              <textarea
                placeholder="Enter your comment"
                className="pickup-request-action-modal-content-field-comments"
                onChange={this.handleReasonChange}
                data-test-id="declineAllReason"
              >
              </textarea>
            </div>
          </div>
          <div className="pickup-request-action-modal-buttons">
            <button
              className="pickup-request-action-button pickup-request-decline-all-button"
              onClick={() => {
                this.declineAllPickupRequests();
                this.closeDeclineAllModal();
              }}
              data-test-id="declineAllModalBtn"
            >
              <p className="pickup-request-button-text pickup-request-action-button-text decline-all">{Strings.decline}</p>
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  getRescheduleModal() {
    return (
      <Modal
        open={this.state.showRescheduleModal}
        onClose={this.closeRescheduleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="rescheduleModal"
      >
        <div className="pickup-request-action-modal">
          <div className="pickup-request-action-modal-title">
            <p className="pickup-request-action-modal-title-text">{Strings.reschedulePickupHeading}</p>
            <img
              src={crossIcon}
              alt="Close"
              className="pickup-request-action-modal-close-btn"
              onClick={this.closeRescheduleModal}
              data-test-id="closeRescheduleModalBtn"
            />
          </div>
          <div className="pickup-request-action-modal-content">
            <div className="pickup-request-action-modal-content-field">
              <p className="pickup-request-action-modal-content-field-label">{Strings.clientName}</p>
              <input
                type="text"
                className="pickup-request-action-modal-content-field-input disabled-input"
                value={this.state.selectedPickup.clientName}
                disabled
              />
            </div>
            <div className="pickup-request-action-modal-content-field">
              <p className="pickup-request-action-modal-content-field-label">{Strings.chooseDate}</p>
              <div className="pickup-request-action-modal-content-field-input-container">
                <span className="date-wrapper">
                  <input
                    type="date"
                    data-test-id="rescheduleDate"
                    className="pickup-request-action-modal-content-field-input date-opacity"
                    onChange={this.handlePickupDateChange}
                  />
                  <p className="date-text">
                    {
                      this.state.rescheduleDate
                        ? this.formatDateText(this.state.rescheduleDate)
                        : "DD/MM/YYYY"
                    }
                  </p>
                  <span>
                    <img
                      src={calendar}
                      style={{ width: "1.25rem", height: "1.25rem", paddingRight: "0.5rem" }}
                    />
                  </span>
                </span>
              </div>
              {!this.isValidRescheduleDate() && <p className="pickup-request-reschedule-date-validation">Re-schedule Date should not be equal to original Pick Up Date</p>}
            </div>
            <div className="pickup-request-action-modal-content-field">
              <p className="pickup-request-action-modal-content-field-label">{Strings.reasonForreSchedule}</p>
              <textarea
                placeholder={Strings.enterComments}
                className="pickup-request-action-modal-content-field-comments"
                onChange={this.handleReasonChange}
                data-test-id="rescheduleReason"
              >
              </textarea>
            </div>
          </div>
          <div className="pickup-request-action-modal-buttons">
            <button
              className="pickup-request-action-button pickup-request-save-button"
              onClick={() => {
                this.reschedulePickupRequest(this.state.selectedPickup.requestId);
                this.closeRescheduleModal();
              }}
              data-test-id="rescheduleBtn"
              disabled={this.shouldDisableRescheduleSaveButton()}
            >
              <p className="pickup-request-button-text pickup-request-action-button-text">{Strings.save}</p>
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  getViewReasonModal() {
    return (
      <Modal
        open={this.state.showReasonModal}
        onClose={this.closeReasonModal}
        data-test-id="reasonModal"
      >
        <div className="pickup-request-action-reason-modal">
          <div className="pickup-request-action-reason-modal-title">
            <img
              src={close}
              alt="Close"
              className="pickup-request-action-reason-modal-close-btn"
              onClick={this.closeReasonModal}
              data-test-id="closeReasonModalBtn"
            />
            <p className="pickup-request-action-modal-title-text">{Strings.reason}</p>
          </div>
          <div className="pickup-request-action-reason-modal-content">{this.state.actionReason}</div>
        </div>
      </Modal>
    );
  }

  getNoResultSection() {
    return (
      <div className="pickup-request-no-result">
        <img src={noResult} alt="No Result" className="pickup-request-no-result-img" />
        <p
          className="pickup-request-no-result-message"
          data-test-id="noResultMessage"
        >
          {Strings.nothingToShow}
        </p>
      </div>
    );
  }

  isArabic = localStorage.getItem("language") == "arabic";

  renderStyleOnLanguage: any = (c: string) => {
    const styles: { [key: string]: any } = {
      textAlign: { textAlign: this.isArabic ? "left" : "" },
      left: { left: this.isArabic ? "3rem" : "" },
      marginLeft: { marginLeft: this.isArabic ? "0" : "-15rem" },
      sortMenuRightLeft: { right: this.isArabic ? "auto" : "-27px", left: this.isArabic ? "2px" : "auto" },
      footertext: { marginLeft: this.isArabic ? "0" : "1.5rem", marginRight: this.isArabic ? "1.5rem" : "0" },
      filterModalArabic: { right: this.isArabic ? "auto" : "-12px", left: this.isArabic ? "2px" : "auto" }
    };

    return styles[c] || {};
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const statusTabs = [
      Strings.pending,
      Strings.accpeted,
      Strings.declined
    ];

    return (
      //Merge Engine DefaultContainer
      <div id="element" className="main-wrapper">
        {this.getDeclineModal()}
        {this.getRescheduleModal()}
        {this.getDeclineAllModal()}
        {this.getViewReasonModal()}
        <NavigationMenu navigation={this.props.navigation} id={""} />
        <div className="pickup_container_wrapper">
          <div className="pickup_navbar_container" style={{display:"flex",width:"100%",justifyContent:"end"}}>
            <Navbar navigation={this.props.navigation} id={""} />
          </div>
          <div className="pickup_body_container">
            <div className="pickup-request-title">
              <div className="pickup-request-heading">{Strings.pickupHeading}</div>
              {this.state.alertMessage && <p className="pickup-request-action-status-alert" data-test-message="alertMessage">{this.state.alertMessage}</p>}
              <div className="pickup-request-action-buttons">
                {
                  this.state.activeTabIndex === 0 ? (
                    <>
                      <button
                        className="pickup-request-action-button pickup-request-accept-all-button"
                        onClick={this.acceptAllPickupRequests}
                        data-test-id="acceptAllBtn"
                      >
                        <img className="hide-tabsmall-element" src={tickCircle} alt="acceptAll" />
                        <p className="pickup-request-button-text pickup-request-action-button-text">{Strings.acceptAll}</p>
                      </button>
                      <button
                        className="pickup-request-action-button pickup-request-decline-all-button"
                        onClick={this.openDeclineAllModal}
                        data-test-id="declineAllBtn"
                      >
                        <img className="hide-tabsmall-element" src={trash} alt="declineAll" />
                        <p className="pickup-request-button-text pickup-request-action-button-text">{Strings.declineAll}</p>
                      </button>
                    </>
                  ) : null
                }
              </div>
            </div>
            <Grid container spacing={3}>
              <div className='clientlisitning-sub-header-container'>
                <div className="change-screen-tabs">
                  {
                    statusTabs.map((tab: string, index: number) => {
                      { return this.pickupRequestStatusTabs(tab, index) }
                    })
                  }
                </div>
                <div className="listing-subheader-right-container">
                  <div className="search_container hide-mobbig-element">
                    <SearchIcon style={{ marginLeft: "8px", marginRight: "8px", color: "#858D9D" }} />
                    <input
                      type="text"
                      placeholder={Strings.search}
                      data-test-id="searchField_1"
                      onChange={(event) => { this.onChangeSearch(event.target.value); }}
                      value={this.state.searchQuery.search_term}
                    />
                  </div>
                  <div className="client_listing_filter_container">
                    <button
                      className="pickup-request-action-button pickup-request-filter-button"
                      onClick={this.onFilterClick}
                      data-test-id="filterBtn"
                      ref={this.filterButtonRef}
                    >
                      <img src={filter} alt="filter" className="icon" />
                      <p className="hide-tabsmall-element" style={{color : 'var(--Neutral-Gray-Gray-500, #667085)'}}>{Strings.filters}</p>
                    </button>
                    {this.state.filterModal && this.getFilterModal()}
                  </div>

                  <div className="client_listing_sort_container">
                    <button
                      className="pickup-request-action-button pickup-request-sort-button"
                      onClick={this.onSortClick}
                      data-test-id="sortBtn"
                      ref={this.sortButtonRef}
                    >
                      <img src={sort} alt="sort" className="icon" />
                      <p className="hide-tabsmall-element" style={{color : 'var(--Neutral-Gray-Gray-500, #667085)'}}>{Strings.sort}</p>
                    </button>
                    {this.state.sortModal && this.getSortModal()}
                  </div>
                </div>
              </div>

              {/* search mobile */}
              <div className="search_container search_container_mob_big hide-mobbig-element">
                <SearchIcon style={{ marginLeft: "8px", marginRight: "8px", color: "#858D9D" }} />
                <input
                  type="text"
                  placeholder={Strings.search}
                  data-test-id="searchField_2"
                  onChange={(event) => { this.onChangeSearch(event.target.value); }}
                  value={this.state.searchQuery.search_term}
                />
              </div>
              {
                this.state.pickupRequestData.length === 0 && (
                  <div
                    className="pickup-request-data"
                    style={{
                      width: "100%",
                      minHeight: "70vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    {this.getNoResultSection()}
                  </div>
                )
              }
              {
                this.state.pickupRequestData.length > 0 && (
                  <Grid item sm={12} style={{ paddingRight: "12px", paddingTop: "12px", paddingLeft: 0}}>
                    <div className="pickup-request-data-table-wrapper" data-test-id="tableWrapper">
                      <table className="pickup-request-data-table" data-test-id="tableContainer">
                        <thead data-test-id="tableHeading">
                          <tr className="pickup-request-data-table-row" style={this.renderStyleOnLanguage("textAlign")} data-test-id="tableRow">
                            <th
                              className="pickup-request-data-table-cell table-heading"
                              data-test-id="tableCell"
                              style={{ width: "3%" }}
                            >
                              <img
                                src={this.state.selectedPickupRequests.length === 0 ? checkbox : minusIcon}
                                alt="Checkbox"
                                className={`pickup-request-select-icon ${this.state.selectedPickupRequests.length > 0 ? "pickup-request-checkbox-img" : ""}`}
                                onClick={this.selectDeselectAllPickupRequests}
                                data-test-id="selectAllBtn"
                              />
                            </th>
                            <th className="pickup-request-data-table-cell table-heading" style={{ width: "16%" }}>{Strings.name}</th>
                            <th className="pickup-request-data-table-cell table-heading" style={{ width: "16%" }}>{Strings.address}</th>
                            <th className="pickup-request-data-table-cell table-heading" style={{ width: "16%" }}>{Strings.branch}</th>
                            <th className="pickup-request-data-table-cell table-heading" style={{ width: "16%" }}>{Strings.city}</th>
                            {
                              this.state.activeTabIndex === 1 &&
                              <th
                                className="pickup-request-data-table-cell table-heading reason-column"
                                style={{ width: "17%" }}
                              >
                                {Strings.reasonForreSchedule}
                              </th>
                            }
                            {
                              this.state.activeTabIndex === 2 &&
                              <th
                                className="pickup-request-data-table-cell table-heading reason-column"
                                style={{ width: "17%" }}
                              >
                                {Strings.reason}
                              </th>
                            }
                            <th className="pickup-request-data-table-cell table-heading" style={{ width: "16%" }}>{Strings.pickupdate}</th>
                            {
                              this.state.activeTabIndex === 0 &&
                              <th className="pickup-request-data-table-cell table-heading" style={{ width: "16%" }}>{Strings.action}</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.pickupRequestData.map((row: any, index: number) => {
                              return (
                                <tr
                                  key={index}
                                  style={this.renderStyleOnLanguage("textAlign")}
                                  className={`pickup-request-data-table-row ${this.isPickupRequestSelected(row.id) ? "pickup-request-data-table-row-selected" : ""}`}
                                >
                                  <td className="pickup-request-data-table-cell">
                                    <img
                                      src={!this.isPickupRequestSelected(row.id) ? checkbox : checkIcon}
                                      alt="Checkbox"
                                      className="pickup-request-select-icon"
                                      onClick={() => this.selectPickupRequest(row.id)}
                                      data-test-id={`selectRequestBtn${index}`}
                                    />
                                  </td>
                                  <td className="pickup-request-data-table-cell client-name">{row.attributes.client.company_name}</td>
                                  <td className="pickup-request-data-table-cell data-cell">{row.attributes.branch.attributes.address}</td>
                                  <td className="pickup-request-data-table-cell data-cell">{row.attributes.branch.attributes.name}</td>
                                  <td className="pickup-request-data-table-cell data-cell">{row.attributes.branch.attributes.city}</td>
                                  {
                                    this.state.activeTabIndex === 1
                                      ? (
                                        <td
                                          className="pickup-request-data-table-cell data-cell">
                                          {
                                            row.attributes.reschedule_reason
                                              ? row.attributes.reschedule_reason.substring(0, 50)
                                              : <p>NA</p>
                                          }
                                          {
                                            (row.attributes.reschedule_reason && row.attributes.reschedule_reason.length > 50) &&
                                            <p
                                              className="pickup-request-view-reason"
                                              onClick={() => this.openReasonModal(row.attributes.reschedule_reason)}
                                              data-test-id={`viewRescheduleReason${index}`}
                                            >
                                              See more
                                            </p>
                                          }
                                        </td>
                                      )
                                      : null
                                  }
                                  {
                                    this.state.activeTabIndex === 2
                                      ? (
                                        <td className="pickup-request-data-table-cell data-cell">
                                          {
                                            row.attributes.rejection_reason
                                              ? row.attributes.rejection_reason.substring(0, 50)
                                              : <p>N/A</p>
                                          }
                                          {
                                            (row.attributes.rejection_reason && row.attributes.rejection_reason.length > 50) &&
                                            <p
                                              className="pickup-request-view-reason"
                                              onClick={() => this.openReasonModal(row.attributes.rejection_reason)}
                                              data-test-id={`viewRejectionReason${index}`}
                                            >See more</p>
                                          }
                                        </td>
                                      )
                                      : null
                                  }
                                  <td className="pickup-request-data-table-cell data-cell">{this.formatDate(row.attributes.pick_up_date)}</td>
                                  {
                                    this.state.activeTabIndex === 0
                                      ? (
                                        <td className="pickup-request-data-table-cell data-cell">
                                          <img
                                            src={moreIcon}
                                            alt="Actions"
                                            className="pickup-request-more-action"
                                            data-test-id={`pickupRequestActionBtn${index}`}
                                            onClick={() => this.openActionMenu(index)}
                                            ref={this.actionsButtonRef}
                                          />
                                        </td>
                                      ) : null
                                  }
                                  {
                                    this.state.menuIndex === index && this.getActionMenu(row.id, row.attributes.client.company_name, row.attributes.pick_up_date)
                                  }
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                      <div className="pickup-request-table-footer-container">
                        {this.getPaginationCaption()}
                        {this.getPages()}
                      </div>
                    </div>
                  </Grid>
                )
              }
            </Grid>
          </div>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const InputField = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      fontSize: "1rem",
      fontFamily: "Barlow Semi Condensed",
    },
    "& .MuiOutlinedInput-root": {
      background: "#FFF",
      borderRadius: "0.5rem",
      width: "12.5rem",
      "&:hover fieldset": {
        borderColor: "initial",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "initial",
        borderWidth: 1,
      },
    },
  },
})(TextField);

// Customizable Area End
