import React from "react";
import "./clientListFilter.css"
import ClientListFilterController, {
  Props, SP
} from "./ClientListFilterController.web";
import { withStyles } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { calendarIcon, chcekIcon, unChcekIcon } from "../assets";
import { Formik, Form } from "formik";
import moment from "moment";
import Strings from "../Strings";



export default class ClientListFilter extends ClientListFilterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleLanguageSwitch = () =>{
    return this.state.switchLanguage === 'english' ? 'filter_modal_wrapper' : 'filter_modal_wrapper_arabic'
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <React.Fragment>
        <Formik
          data-test-id="formik"
          initialValues = {this.state.initialValues}
          enableReinitialize={true}
          onSubmit={(values) => { this.handleSubmit(values) }}
          validationSchema={this.formSchema}
          innerRef={this.formRef}
        >

          {(props) => {
             const {
              values,
              setFieldValue,
              resetForm
          } = props;
            return (
              <Form translate={undefined} className="formContainer">
                <div className={this.handleLanguageSwitch()}>
                  <div className={this.state.switchLanguage === 'english' ? 'client_filter_menu_clear_wrapper' : "client_filter_menu_clear_wrapper_arabic"}>
                    <p data-test-id="reset-form" onClick={this.clearFormValue(resetForm)}>{Strings.clearAll}</p>
                  </div>
                  {/*  */}
                  <div className="client_filter_accordian_wrapper">
                    {/* accordian */}

                    {/* accordian 1 */}
                    <Accordion data-test-id="clientaccordian" square expanded={this.state.expanded === 'panel1'} onChange={this.handleAcordionChange('panel1')}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}>
                        <p className={`client_accordian_header ${this.state.expanded === 'panel1' && 'filter_accordian_active_header_color'}`}>{Strings.clients}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordionDetails_wrapper">
                          {this.props.companyList.map((companyObj:any,comIndex:number)=>{
                            return (
                                <div className="checkbbox_wrapper" key={comIndex}>
                                  <img data-test-id={`company-checkbox-${comIndex}`} src={values.clientsId.includes(companyObj[0])?chcekIcon : unChcekIcon} alt="chcekIcon" className="checkbox_img" onClick={this.setCompanyId(companyObj[0],values,setFieldValue)} />
                                  <p className="checkbox_title">{companyObj[1]}</p>
                                </div>
                            )
                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* accordian 2 */}
                    <Accordion data-test-id="dateaccordian" square expanded={this.state.expanded === 'panel2'} onChange={this.handleAcordionChange('panel2')}>
                      <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}>
                        <p className={`client_accordian_header ${this.state.expanded === 'panel2' && 'filter_accordian_active_header_color'}`}>{Strings.pickupDate}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordionDetails_wrapper">
                          {this.state.picup_date_array.map((pick_obj:SP,pickIndex:number)=>{
                              return (
                                <div data-test-id={`pickUpDate${pickIndex}`} className="checkbbox_wrapper" key={pickIndex} >
                                    <img data-test-id={`pickup-checkbox-${pickIndex}`} src={values.pickUpDate === pick_obj.value ? chcekIcon : unChcekIcon} alt="chcekIcon" className="checkbox_img" onClick={this.setPickValue(pick_obj.value,values,setFieldValue)} />
                                    <p className="checkbox_title">{pick_obj.title}</p>
                                </div>
                              )
                          })}
                          {values.pickUpDate === 'custom_date' && 
                          <div className="custom_date_wrapper">
                            <div className="custom_date_input_wrapper">
                              <p className="custom_date_input_title">From </p>

                              <div className="custom_date_input_container" >
                                <input className="input_date_hide"
                                  data-test-id = "custom-start-date"
                                  id="from_date_type"
                                  type="date"
                                  value={moment(values.custom_start_date,'DD-MM-yyyy').format('yyyy-MM-DD')}
                                  onChange={this.setCustomStartDate(values,setFieldValue)}
                                  max={moment().format('yyyy-MM-DD')}
                                   />
                                <p className="custom_date_input_text">{values.custom_start_date}</p>
                                <img src={calendarIcon} alt="calander_icon" />
                              </div>
                            </div>

                            <div className="custom_date_input_wrapper">
                              <p className="custom_date_input_title">To </p>
                              <div className="custom_date_input_container" >
                                <input className="input_date_hide"
                                 data-test-id = "custom-end-date"
                                 id="from_date_type"
                                 type="date"
                                 value={moment(values.custom_end_date, 'DD-MM-yyyy').format('yyyy-MM-DD')}
                                 onChange={this.setCustomEndDate(setFieldValue)}
                                 max={moment().format('yyyy-MM-DD')}
                                 min={values.custom_start_date}
                                  />
                                <p className="custom_date_input_text">{values.custom_end_date}</p>
                                <img src={calendarIcon} alt="calander_icon" />
                              </div>
                            </div>
                          </div>
                          }


                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* accordian 3 */}
                    <Accordion data-test-id='cityaccordian' square expanded={this.state.expanded === 'panel3'} onChange={this.handleAcordionChange('panel3')}>
                      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}>
                        <p className={`client_accordian_header ${this.state.expanded === 'panel3' && 'filter_accordian_active_header_color'}`}>{Strings.cityLabel}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordionDetails_wrapper">
                        {this.props.cityList.map((cityObj:any,cityIndex:number)=>{
                            return (
                                <div className="checkbbox_wrapper" key={cityIndex}>
                                  <img data-test-id={`city-checkbox-${cityIndex}`} src={values.cityNames.includes(cityObj[1])?chcekIcon : unChcekIcon} alt="chcekIcon" className="checkbox_img" onClick={this.setCityName(cityObj[1],values,setFieldValue)} />
                                  <p className="checkbox_title">{cityObj[1]}</p>
                                </div>
                            )
                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/*  */}
                  </div>
                  {/*  */}
                  <div className={this.state.switchLanguage === 'english'?"client_filter_bottom_wraper":"client_filter_bottom_wraper_arabic"}>
                          <button data-test-id="cancle-btn" className="client_filter_bottom_cancel_btn" onClick={this.cancelFilterBtn}>{Strings.cancel}</button>
                          <button className="client_filter_bottom_apply_btn" type="submit">{Strings.apply}</button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </React.Fragment>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Accordion = withStyles({
  root: {
    borderBottom: '1px solid rgba(141, 151, 162, 0.4)',
    boxShadow: 'none',
    // '&:not(:last-child)': {
    //   borderBottom: 0,
    // },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: '0px 7px',
    marginBottom: -1,
    minHeight: 54,
    '&$expanded': {
      minHeight: 36,
    },
  },
  content: {
    '&$expanded': {
      margin: '14px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);


// Customizable Area End
