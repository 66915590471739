import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import { SQS } from "./DashboardController.web";
import { checkIcon, iconDelete, iconFilter, uncheckIcon } from "./assets";
import {
  ClickAwayListener,
} from "@material-ui/core";
import ServiceMaintainanceFilter from "./ServiceMaintainanceFilter.web";
import "./serviceMaintainancePopup.css"
import Strings from './Strings'

export interface PopUpProps {
    testId?: string
    isOpen: boolean
    listServices: Array<any>
    onPressClose: () => void
    cityList: Array<any>
    searchQueryState: any
    setSearchQueryState: (search: SQS, callAPI: boolean) => void
    onDelete: (list: Array<string>) => void
}

interface PopUpState {
    showFilterModal: boolean
    listChosenId: Array<string>
}

export default class ServiceMaintainancePopUp extends React.Component<PopUpProps, PopUpState> {
    constructor(props: PopUpProps) {
        super(props);
        this.state = {
            showFilterModal: false,
            listChosenId: []
        }
    }

    showFilter() {
        this.setState({ showFilterModal: true })
    }

    hideFilter() {
        this.setState({ showFilterModal: false })
    }

    onclose() {
        this.setState({ listChosenId: [] })
        this.hideFilter()
        this.props.onPressClose()
    }

    selectItem(value: string) {
        let currentList = [...this.state.listChosenId]
        if(currentList.includes(value)) {
            const index = this.state.listChosenId.indexOf(value);
            currentList.splice(index, 1);
        } else {
            currentList.push(value)
        }
        this.setState({ listChosenId: [...currentList] })
    }

    isArabic = localStorage.getItem("language") == "arabic";

    renderStyleOnLanguage: any = (c: string) => {
        const styles: { [key: string]: any } = {
            filterModalRightLeft: {
                right: this.isArabic ? "auto" : "28px",
                left: this.isArabic ? "28px" : "auto"
            }
        };

        return styles[c] || {};
    };

    render() {
        const { isOpen, listServices, cityList, searchQueryState, setSearchQueryState, onDelete } = this.props
        if (isOpen) {
            return (
                <div style={webPopUpStyle.viewPopUp}>
                    <div
                        onClick={() => this.onclose()}
                        style={webPopUpStyle.viewBackground}
                        data-test-id="backgroundClose"
                    />
                    <div className="viewContentPopUp">
                        <div data-test-id="iconClose" onClick={() => this.onclose()} style={webPopUpStyle.closeIcon}>
                            <CloseIcon style={{ color: "#858D9D" }} />
                        </div>
                        <div style={webPopUpStyle.viewHeaderPopUp}>
                            <p style={webPopUpStyle.headerText}>{Strings.serviceHeader}</p>
                            <div style={webPopUpStyle.rowButton}>
                                <div style={webPopUpStyle.buttonDelete} data-test-id="deleteButton" onClick={() => onDelete(this.state.listChosenId)}>
                                    <img src={iconDelete} style={webPopUpStyle.icon} />
                                    <p className="hide-mob-element" style={webPopUpStyle.titleDelete}>{Strings.deleteAll}</p>
                                </div>
                                <div style={webPopUpStyle.buttonFilter} data-test-id="filterButton" onClick={() => this.showFilter()}>
                                    <img src={iconFilter} style={webPopUpStyle.icon} />
                                    <p className="hide-mob-element" style={webPopUpStyle.titleFilter}>{Strings.filter}</p>
                                    {
                                        this.state.showFilterModal && (
                                            <ClickAwayListener onClickAway={() => this.hideFilter()}>
                                                <div style={this.renderStyleOnLanguage("filterModalRightLeft")} className="filter_modal_dashboard_container">
                                                    <ServiceMaintainanceFilter
                                                        testId="filter"
                                                        searchQueryState={searchQueryState}
                                                        setSearchQueryState={setSearchQueryState}
                                                        showFilterModal={() => this.showFilter()}
                                                        hideFilterModal={() => this.hideFilter()}
                                                        cityList={cityList}
                                                    />
                                                </div>
                                            </ClickAwayListener>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <ListService
                            listServices={listServices}
                            isSlice={false}
                            listChosenId={this.state.listChosenId}
                            selectItem={(e: string) => this.selectItem(e)}
                        />
                    </div>
                </div>
            )
        } else return null
    }
}

export const ListService: any = ({
    listServices,
    isSlice,
    listChosenId,
    selectItem
}: {
    listServices: Array<any>
    isSlice: boolean
    listChosenId: Array<string>
    selectItem: (value: string) => void
}) => {
    if(isSlice) {
        return listServices.map((item: any, index: number) => {
            const formatDate = new Date(item.attributes.last_waste_collection).toLocaleDateString('en-GB')
            return (
                <div style={webPopUpStyle.viewItemService} key={`service_${index}`}>
                    <div style={webPopUpStyle.textCompany} className="item_company_title">{item.attributes.company_name}</div>
                    <div style={webPopUpStyle.textDescription} className="item_description">{`${item.attributes.branch_name}, ${item.attributes.city}`}</div>
                    <div style={webPopUpStyle.textDescriptionDate} className="item_description_date">{`${Strings.lastwasteCollection}: ${formatDate}`}</div>
                </div>
            )
        }) 
    } else {
        return listServices.map((item: any, index: number) => {
            const formatDate = new Date(item.attributes.last_waste_collection).toLocaleDateString('en-GB')
            const onClickIcon = () => {
                selectItem(item.id)
            }
            return (
                <div style={webPopUpStyle.viewItemService} key={`service_${index}`}>
                    <div style={webPopUpStyle.rowItem} onClick={onClickIcon}>
                        <img 
                            data-test-id={`checkbox-${item.id}`} 
                            src={listChosenId.includes(item.id) ? checkIcon : uncheckIcon} 
                            alt="checkIcon" 
                        />
                        <div style={webPopUpStyle.item}>
                            <div style={webPopUpStyle.textCompany}>{item.attributes.company_name}</div>
                            <div style={webPopUpStyle.textDescription}>{`${item.attributes.branch_name}, ${item.attributes.city} | ${Strings.lastwasteCollection}: ${formatDate}`}</div>
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "1px", backgroundColor: "#F0F1F3"}} />
                </div>
            )
        }) 
    }
}

export const webPopUpStyle = {
    viewItemService: {
        display: "flex",
        width: "100%",
        marginBottom: "18px",
        flexDirection: "column"
    } as any,
    textCompany: {
        color: "#32569A",
        fontFamily: 'Barlow Semi Condensed',
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "28px",
        letterSpacing: "0.16px",
    } as any,
    textDescription: {
        color: "#1C3045",
        fontFamily: 'Barlow Semi Condensed',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.14px",
    } as any,
    textDescriptionDate: {
        color: "#1C3045",
        fontFamily: 'Barlow Semi Condensed',
        fontSize: "14px",
        fontWeight: "500",
        letterSpacing: "0.14px",
    } as any,
    viewContentPopUp: {
        position: "absolute",
        background: "white",
        height: "60%",
        width: "40%",
        margin: "auto",
        padding: "24px",
        border: "1px solid #000",
        borderRadius: "12px",
        boxShadow: "1px solid black",
    } as any,
    viewBackground: {
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
    },
    viewPopUp: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 10000
    } as any,
    closeIcon: {
        position: "absolute",
        right: "20px",
        top: "20px"
    } as any,
    icon: {
        width: "20px",
        height: "20px",
    },
    viewHeaderPopUp: {
        marginTop: "40px",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px"
    } as any,
    headerText: {
        color: "#1D1F2C",
        fontFamily: "Barlow Semi Condensed",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
        letterSpacing: "0.2px"
    },
    rowButton: {
        display: 'flex',
        flexDirection: "row",
    } as any,
    buttonDelete: {
        padding: "8px 16px",
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#DC2626",
        borderRadius: "8px",
    } as any,
    buttonFilter: {
        padding: "8px 16px",
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #E0E2E7",
        marginLeft: localStorage.getItem("language") == "english" ? "16px" : "",
        marginRight: localStorage.getItem("language") == "arabic" ? "16px" : ""
    } as any,
    titleDelete: {
        color: "#fff",
        fontFamily: "Barlow Semi Condensed",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        marginLeft: "8px"
    },
    titleFilter: {
        color: "#667085",
        fontFamily: "Barlow Semi Condensed",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.07px",
        marginLeft: "8px"
    },
    rowItem: {
        display: "flex",
        flexDirection: "row",
    } as any,
    item: {
        display: "flex",
        flexDirection: "column",
        marginLeft: localStorage.getItem("language") == "english" ? "16px" : "",
        marginRight: localStorage.getItem("language") == "arabic" ? "16px" : ""
    } as any,
}
