import React from "react";
import Strings from './Strings'
import { checkRadioImage, unCheckRadioImage } from "./assets";
import { VALUE_SORT_TOTAL_WEIGHT } from "./ReportClientController.web";
const configJson = require("../src/config.js")

interface Props {
  onChangeSortByChoice?: (v: string) => void
}

const LIST_CHOICE = [Strings.NewToOld, Strings.oldToNew, Strings.AscendingTitleAZ, Strings.DescendingTitleZA,]


export default class CustomSortModal extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      choice: configJson.NewToOld
    }
  }

  handleClickChoice(value: string) {
    if (value === configJson.NewToOld) {
      this.props.onChangeSortByChoice?.(VALUE_SORT_TOTAL_WEIGHT.NEWEST_TO_OLDEST)
    } else if (value === configJson.oldToNew) {
      this.props.onChangeSortByChoice?.(VALUE_SORT_TOTAL_WEIGHT.OLDEST_TO_NEWEST)
    } else if (value === configJson.AscendingTitle) {
      this.props.onChangeSortByChoice?.(VALUE_SORT_TOTAL_WEIGHT.ASCENDING_A_Z)
    } else {
      this.props.onChangeSortByChoice?.(VALUE_SORT_TOTAL_WEIGHT.DESCENDING_Z_A)
    }
    this.setState({ choice: value })
  }

  render() {
    return (
      <div style={CustomStyles.modalWrapper}>
        <div style={CustomStyles.sortMenuTitleContainer}>
          <p style={CustomStyles.sortMenuTitle}>{Strings.SortTitle}</p>
        </div>
        {
          LIST_CHOICE.map((item: string, ind: number) => (
            <div
              data-test-id={`CHOICE_${ind}`}
              style={CustomStyles.sortMenuContainer}
              onClick={() => this.handleClickChoice(item)}
              key={ind}
            >
              <div
                style={CustomStyles.sortMenuImageContainer}
              >
                <img
                  src={this.state.choice === item ? checkRadioImage : unCheckRadioImage}
                  alt={configJson.checkRadioAlt}
                  style={CustomStyles.checkRadioImageStyle}
                />
              </div>
              <p style={CustomStyles.sortMenuText}>{item}</p>
            </div>
          ))
        }
      </div>
    );
  }
}


const CustomStyles = {
  modalWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    width: "300px !important",
    flexShrink: 0,
    borderRadius: "0.5rem",
    border: "1px solid var(--neutrals-cool-gray-200, #e2e8f0)",
    background: "#FFFFFF",
    alignItems: "center",
  },
  sortMenuTitleContainer: {
    marginTop: "12px",
    width: "289px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  sortMenuText: {
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
  },
  sortMenuTitle: {
    marginLeft: localStorage.getItem("language") == "arabic" ? "0" : "16px",
    marginRight: localStorage.getItem("language") == "arabic" ? "16px" : "0",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
  },
  sortMenuContainer: {
    width: "289px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  sortMenuImageContainer: {
    marginLeft: "16px",
    width: "20px",
    height: "20px",
    position: "relative" as "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "12px",
  },
  checkRadioImageStyle: {
    width: "20px",
    height: "20px",
  },
  unCheckRadioImageStyle: {
    position: "absolute" as 'absolute',
    width: "20px",
    height: "20px",
    cursor: "pointer",
    top: "0px",
    left: "0px",
  },
  sortMenuTextIcon: {
    width: "1rem",
    height: "0.5rem",
    flexShrink: 0,
    margin: "0px 1px",
  }
}