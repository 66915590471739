import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from 'yup';



// Customizable Area Start
interface SocialMedia {
  status: boolean;
  url: string;
}
interface Error {
  status: boolean | null;
  message: string;
}

interface formValue {
  name: string,
  email: string,
  phone_number: string,
  description: string
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string
  contactInfo: any
  successModalFlag: boolean
  btnDisableFlag: boolean,
  showErrorFlag: { error: boolean, message: string }

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusUserWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactInfoApiCallId: any;
  submitContactusApiCallId: any;
  setLanguageOptionsAPIId:any;
  formSchema: any
  resetForm: any
  myRef : any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactInfoApiCallId = "";
    this.submitContactusApiCallId = "";
    this.resetForm = null

    this.myRef = React.createRef();


    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    this.formSchema = Yup.object().shape({
      name: Yup.string().required("name Required"),
      email: Yup.string().email().required("Email Required"),
      phone_number: Yup.string().matches(/^[0-9]+$/, 'Phone number is not valid').min(5, 'Must be at least 5 digits').max(15, 'Must be at max 15 digits').required("number Required"),
      description: Yup.string().required("name Required"),

    })


    this.state = {
      token: "",
      contactInfo: {},
      successModalFlag: false,
      btnDisableFlag: false,
      showErrorFlag: { error: false, message: '' }

    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setLanguageForClient();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.data) {

        if (apiRequestCallId === this.contactInfoApiCallId) {
          this.setState({ contactInfo: responseJson.data.attributes });
        }

        if (apiRequestCallId === this.submitContactusApiCallId) {
          this.setState({ successModalFlag: true, btnDisableFlag: false })
          this.myRef.current.resetForm()
        }

      }
      else if (apiRequestCallId === this.setLanguageOptionsAPIId && responseJson) {
        this.getContactInfo();
      } else {
        this.showErrorMessage(apiRequestCallId, errorReponse, responseJson)
      }

    }

    // Customizable Area End
  }

  // Customizable Area Start

  setLanguageForClient() {
    let language = localStorage.getItem("language") === 'english'?"English":"Arabic";
    let token = this.getTokenForContactusUser();
    let data={
      language
    }

    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.setLanguageOptionsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setLanguageEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  showErrorMessage = (apiRequestCallId: any, errorReponse: any, responseJson: any) => {
    // show error message submitContactusApiCallId
    if (apiRequestCallId === this.submitContactusApiCallId) {
      if (responseJson.errors) {
        let error = Object.keys(responseJson.errors[0])
        let errorMessage = `${error[0].split('_').join(' ')}  ${responseJson.errors[0][error[0]]}`
        this.setState({ showErrorFlag: { error: true, message: errorMessage }, btnDisableFlag: false })
      } else {
        this.setState({ showErrorFlag: { error: true, message: configJSON.defaultsErrorMessageUserForm }, btnDisableFlag: false })
        this.myRef.current.resetForm()
      }
      let element: any = document.querySelector('#root div');
      element.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
    // -----------
  }


  getContactInfo = () => {
    let temp = this.getTokenForContactusUser();

    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: temp,
      "ngrok-skip-browser-warning": '69420'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactInfoApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactInfoAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  handleSubmit = (value: formValue) => {
    this.setState({ btnDisableFlag: true, showErrorFlag: { error: false, message: '' } })

    let temp = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: temp,
      "ngrok-skip-browser-warning": '69420'
    };

    let data = {
      data: value,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitContactusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitContactformAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  closeSuccessModal = () => {
    this.setState({ successModalFlag: false })
  }

  getTokenForContactusUser = () => {
    const clientDetails: any = sessionStorage.getItem("clientDetails");
    const token = localStorage.getItem("token");
    return (JSON.parse(clientDetails) || {}).token || token;
  }

  shouldDisableSubmit = () => {
    const clientDetails = sessionStorage.getItem("clientDetails");
    return !!clientDetails;
  }

  handleBeforeUnload = () => {
    localStorage.removeItem("clientDetails");
  }

  componentWillUnmount(): any {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }
  // Customizable Area End
}
