import React from "react";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import AddNewWasteBillController, {
  Props,
} from "./AddNewWasteBillController.web";
import { Form, Formik } from "formik";
import "./AddNewWasteBill.css";
import DropDown from "./DropDown.web";
import InputField from "../../../components/src/InputField.web";
import {
  arrowLeft,
  attachment,
  calendar,
  cross,
  download,
  fileUpload,
} from "./assets";
import SuccessModal from "./sucessModal.web";
import { editIcon } from "../../termsconditions/src/assets";
import Strings from "./Strings";
import moment from 'moment';

export default class AddNewWasteBill extends AddNewWasteBillController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getErrorBoxStyles = () => {
    if (this.state.error.status === null) {
      return "";
    } else if (!this.state.error.status) {
      return "prompt-container-success";
    } else {
      return "prompt-container-failure";
    }
  };

  getErrorStyleObject = (error: boolean | null) => {
    if (error !== null) {
      if (error) { return { outline: "none", border: "1px solid rgba(220, 38, 38, 1)" } }return { outline: "none", border: "1px solid #bfcc33" }}
    return {}
  };

  getErrorInputStyle = (errors: any) => {
    if (errors !== null) {
      if (errors) {
        return "error-input";
      }
      return "success-input";
    }
    return "";
  };

  getFileInput=(values:any,setFieldValue:any,lang:string | null)=>{
    if(!this.state.viewMode){ 
      return (
        <div
          className="addwastebill-attachment margin-top-title"
          onClick={this.handleFileUploadClick}
          data-testid={"fileUploadButton"}
        >
          <input
            type="file"
            ref={this.fileInputRef}
            style={{ display: "none" }}
            multiple
            disabled={
              this.state.viewMode && this.state.editId !== -1
            }
            data-testid={"fileUploadInput"}
            onChange={(event: any) => {
              if (event.target.files.length > 0) {
                setFieldValue("attachments", [
                  ...values.attachments,
                  ...event.target.files,
                ]);
              }
            }}
          />
          <div className="addwastebill-attachment-text-container">
            <img
              src={fileUpload}
              className="addwastebill-file-attachment-icon"
            />
            <span
              dir={
                lang === "english"
                  ? "ltr"
                  : "rtl"
              }
              className="addwastebill-file-attachment-text-container"
            >
              <span className={`addwastebill-file-attachment-upper-text`}>
              <p className={`addwastebill-file-attachment-upper-text ${lang==='arabic'?'margin-left-half':""}`}> {Strings.dragAndDropFilesOr}</p>
                <p className={`color-primary-blue ${lang==='english'?'margin-left-half':""}`}>
                  {Strings.browse}
                </p>
              </span>
              <p dir={'ltr'} className={"addwastebill-file-attachment-lower-text"}>
                {Strings.max100KBFileSize}
                {lang==="arabic"&&<p className="margin-left-quater">{'100'}</p>}
              </p>
            </span>
          </div>
        </div>
    )}
  }

  getFilteredClientList = (value: string) => {
    let temp: any[] = this.state.clientList.filter((item: any) => {
      return item.attributes.name.toLowerCase().includes(value.toLowerCase());
    })
    return temp
  };

  reportDateContainerStyle: (error: boolean | null) => any = (error) => {
    let temp: any = {};
    temp = this.state.viewMode ? { border: "1px solid #e0e2e7" } : {};
    temp = { ...this.getErrorStyleObject(error), ...temp };
    return temp;
  };

  isArabic = localStorage.getItem("language") == "arabic";

  renderStyleOnLanguage: any = (c: string) => {
    const styles : { [key: string]: any } = {
      marginLeft: { marginLeft: this.isArabic ? "1rem" : "0" },
      marginRight: { marginRight: this.isArabic ? "0" : "1rem" },
      textAlign: { textAlign: this.isArabic ? "right" : "left" },
      marginLeftInputFieldClient: { marginLeft: this.isArabic ? "0" : "0.875rem" },
      marginRightInputFieldClient: { marginRight: this.isArabic ? "0.875rem" : "0" },
      leftDate: { left: this.isArabic ? "6px" : "" },
      rightDate: { right: this.isArabic ? "auto" : "6px" },
      textAlignAddClass: this.isArabic ? "text-right-on-arabic" : "",
      bottomButtonMargin: {
        marginLeft: this.isArabic ? "2.5rem" : "0",
        marginRight: this.isArabic ? "0" : "2.5rem",
      },
    };
  
    return styles[c] || {};
  };

  getContactUsHeaderTopContainer() {
    return (
      <div
        className="contactus-header-top-container addwastebill-top-container"
        style={{ width: "92%" }}
      >
        <div style={{ display: "flex" }}>
          {this.state.viewMode && (
            <img
              src={arrowLeft}
              data-testid={"backButton"}
              style={webStyles.backButtonImg}
              onClick={this.goBack}
            />
          )}
          <p className="contactus-header-title">{`${this.state.editId === -1
              ? Strings.addNewWastebill
              : this.state.viewMode
                ? Strings.wastebill
                : Strings.editWastebill
            }`}</p>
        </div>
        {this.state.error.status !== null && (
          <span
            data-testid={"prompMessage"}
            className={`contactus-prompt-container header-prompt-container ${this.getErrorBoxStyles()}`}
          >
            {
              Array.isArray(this.state.error.message)
                ? this.state.error.message.join(" ")
                : this.state.error.message
            }
          </span>
        )}

        <button
          data-testid={"editButton"}
          className={`add-button button-fill`}
          style={{
            width: "5.625rem",
            display: `${this.state.viewMode ? "flex" : "none"}`,
          }}
          onClick={this.editButton}
        >
          <img
            src={editIcon}
            style={{
              ...webStyles.backButtonImg,
              marginRight: "0.5rem",
            }}
          />
          <p className="add-button-text">{Strings.edit}</p>
        </button>
      </div>
    );
  }

  getAddWasteBillFormTopContainer(
    values: any,
    errors: any,
    handleChange: any,
    handleBlur: any,
    handleReset: any,
    setFieldValue: any,
    lang: any
  ) {
    return (
      <div className="addwastebill-form-container">
        {this.renderWasteCategoryDropdown(values, errors, handleBlur)}
        {this.renderClientNameDropdown(values, errors, handleReset)}
        {this.renderBranchNameDropdown(values, errors, setFieldValue)}
        {this.renderRecyclableNetWeightInput(values, errors)}
        {this.renderPickupDate(values)}
        {this.renderCommentInput(values, errors, handleChange)}
        {this.renderAttachments(values, setFieldValue, lang)}
        {this.renderImpactFactors(values, errors)}
      </div>
    );
  }

  renderWasteCategoryDropdown(values: any, errors: any, handleBlur: any) {
    return (
      <DropDown
        name={"wasteCatagory"}
        data-testid={"wasteCatagory"}
        value={values.wasteCatagory?.attributes?.name}
        showEndEndornment
        disable={true}
        disableDropdown={this.state.viewMode && this.state.editId !== -1}
        customStyles={this.state.viewMode ? "disabled-dropdown" : ""}
        customStyle={this.state.viewMode ? { border: "1px solid #e0e2e7" } : {}}
        labelName={Strings.wasteCatagory}
        placeholder={Strings.enterWasteCatagory}
        dropDownList={this.state.wasteCatagories}
        currentSelected={values.wasteCatagory?.attributes?.name}
        setCurrentSelected={this.setWasteCategory}
        onBlur={handleBlur}
        error={values.wasteCatagory?.attributes?.name === "" ? null : Boolean(errors.wasteCatagory)}
      />
    );
  }

  renderClientNameDropdown(values: any, errors: any, handleReset: any) {
    return (
      <DropDown
        name="clientName"
        data-testid={"clientName"}
        disable={this.state.viewMode}
        disableDropdown={this.state.viewMode && this.state.editId !== -1}
        value={values.clientName}
        currentSelected={values.clientName?.attributes?.name}
        labelName={Strings.clientName}
        placeholder={Strings.enterClientName}
        customStyles={this.state.viewMode ? "disabled-dropdown" : ""}
        customStyle={this.state.viewMode ? { border: "1px solid #e0e2e7" } : {}}
        dropDownList={this.getFilteredClientList(values.clientName?.attributes?.name)}
        onChangeText={handleReset}
        showEndEndornment={false}
        setCurrentSelected={this.setClientName}
        error={values.clientName?.attributes?.name === "" ? null : Boolean(errors.clientName)}
      />
    );
  }

  renderBranchNameDropdown(values: any, errors: any, setFieldValue: any) {
    return (
      <DropDown
        name={"branchName"}
        data-testid={"branchName"}
        value={values.branchName?.attributes?.name}
        showEndEndornment
        disable={true}
        disableDropdown={this.state.viewMode && this.state.editId !== -1}
        customStyle={this.state.viewMode ? { border: "1px solid #e0e2e7" } : {}}
        labelName={Strings.branchName}
        placeholder={Strings.enterBranchName}
        dropDownList={this.state.branchNames}
        currentSelected={values.branchName?.attributes?.name}
        customStyles={`addwastebill-inputbox ${this.state.viewMode ? "disabled-dropdown" : ""}`}
        setCurrentSelected={setFieldValue}
        error={values.branchName?.attributes?.name === "" ? null : Boolean(errors.branchName)}
      />
    );
  }

  renderRecyclableNetWeightInput(values: any, errors: any) {
    return (
      <InputField
        name={"recyclableNetWeight"}
        data-testid={"recyclableNetWeight"}
        labelName={Strings.recyclableNetWeight}
        value={values.recyclableNetWeight}
        placeholder={Strings.enterWeight}
        disable={this.state.viewMode && this.state.editId !== -1}
        onChangeText={(event) => this.handleRecyclableNetWeight(event, values.wasteCatagory.id)}
        customStyles={`addwastebill-inputbox ${this.state.viewMode ? "disabled-dropdown" : ""}`}
        customStyle={{
          ...(this.state.viewMode ? { border: "1px solid #e0e2e7" } : {}),
          ...this.renderStyleOnLanguage("textAlign")
        }}
        labelStyle={`dropdown-input-name addwastebill-input-name ${this.renderStyleOnLanguage("textAlignAddClass")}`}
        error={values.recyclableNetWeight === "" ? null : Boolean(errors.recyclableNetWeight)}
        inputStyle={{ ...this.renderStyleOnLanguage("marginLeftInputFieldClient"), ...this.renderStyleOnLanguage("marginRightInputFieldClient") }}
      />
    );
  }

  renderPickupDate(values: any) {
    return (
      <div className="account-creation-input-container">
        <label className="dropdown-input-name addwastebill-input-name">
          {Strings.pickupDate}
        </label>
        <span
          style={this.reportDateContainerStyle(values.reportDate === "" ? null : false)}
          className={`addNewWasteBill-date-input ${this.state.viewMode ? "disabled-dropdown" : ""}`}
        >
          <input
            type="date"
            name="reportDate"
            style={{ ...this.renderStyleOnLanguage("marginLeftInputFieldClient"), ...this.renderStyleOnLanguage("marginRightInputFieldClient") }}
            disabled={this.state.viewMode && this.state.editId !== -1}
            data-testid="reportDate"
            className="date-opacity text-field-input"
            value={moment(values.reportDate, 'DD-MM-yyyy').format('yyyy-MM-DD')}
            onChange={this.handleDateChange}
          />
          <p
            style={{ ...this.renderStyleOnLanguage("marginLeftInputFieldClient"), ...this.renderStyleOnLanguage("marginRightInputFieldClient") }}
            className={`addwastebill-date-text ${Boolean(values.reportDate) && "addwastebill-text-color"}`}
          >
            {values.reportDate ? values.reportDate : Strings.selectADate}
          </p>
          <span style={{ ...this.renderStyleOnLanguage("leftDate"), ...this.renderStyleOnLanguage("rightDate") }} className="adornment">
            <img src={calendar} />
          </span>
        </span>
      </div>
    );
  }

  renderCommentInput(values: any, errors: any, handleChange: any) {
    return (
      <InputField
        value={values.comment}
        type="text"
        multiline={true}
        onChangeText={handleChange}
        disable={this.state.viewMode && this.state.editId !== -1}
        data-testid="comment"
        labelStyle={`dropdown-input-name addwastebill-input-name ${this.renderStyleOnLanguage("textAlignAddClass")}`}
        name={"comment"}
        customStyle={this.state.viewMode ? { border: "1px solid #e0e2e7" } : {}}
        customStyles={`addwastebill-comment-input ${this.state.viewMode ? "disabled-dropdown" : ""}`}
        placeholder={Strings.enterYourComments}
        labelName={Strings.comment}
        error={values.comment === "" ? null : Boolean(errors.comment)}
        inputStyle={{ ...this.renderStyleOnLanguage("marginLeftInputFieldClient"), ...this.renderStyleOnLanguage("marginRightInputFieldClient") }}
      />
    );
  }

  renderAttachments(values: any, setFieldValue: any, lang: any) {
    return (
      <div className="account-creation-input-container">
        <label className="dropdown-input-name addwastebill-input-name addwastebill-bottom-label-text">
          {Strings.attachments}
        </label>
        {this.getFileInput(values, setFieldValue, lang)}
        <div className="addwastebill-attachments">
          {values.attachments.length > 0 && values.attachments.map((item: any, index: number) => {
            if (!item?._destroy) {
              return this.renderAttachmentItem(item, index);
            }
            return null;
          })}
        </div>
      </div>
    );
  }

  renderAttachmentItem(item: any, index: any) {
    return (
      <div
        key={index}
        data-testid={`files${index + 1}`}
        style={this.state.viewMode ? { backgroundColor: "#F1F5F9" } : {}}
        className="addwastebill-attachment-outer-container"
      >
        <div className="addwastebill-attachment-inner-container">
          <div className="addwastebill-img-icon-container">
            <img src={attachment} className="addwastebill-img-icon" />
          </div>
          <div className="addwastebill-title-container">
            <p className="addw astebill-attachment-title">{item.name}</p>
            <p className="addwastebill-attachment-title-footer">
              {this.state.viewMode ? Strings.view : Strings.completed}
            </p>
          </div>
        </div>
        {this.state.viewMode ? (
          <img
            src={download}
            style={webStyles.cursorPointer}
            data-testid={`attachDownloadButton${index}`}
            className="addwastebill-img-cross-icon"
            onClick={() => this.handleDownload(item)}
          />
        ) : (
          <img
            src={cross}
            style={webStyles.cursorPointer}
            data-testid={`attachDeleteButton${index}`}
            className="addwastebill-img-cross-icon"
            onClick={() => this.handleDeleteFile(index)}
          />
        )}
      </div>
    );
  }

  renderImpactFactors(values: any, errors: any) {
    return (
      <div className="bottom-fields-container">
        <label className="dropdown-input-name addwastebill-input-name" style={{ color: "#000" }}>
          {Strings.impactFactors}
        </label>
        <div className="addwastebill-flex-row-space-between addwastebill-top-margin15">
          {this.renderImpactFactorInput(values.treeSaved, errors.treeSaved, Strings.treeSaved, Strings.trees, "treeSaved")}
          {this.renderImpactFactorInput(values.energySaved, errors.energySaved, Strings.kilowattEnergySaved, Strings.kWh, "energySaved")}
        </div>
        <div className="addwastebill-flex-row-space-between">
          {this.renderImpactFactorInput(values.co2Reduction, errors.co2Reduction, Strings.co2EqReduction, Strings.Kg, "co2Reduction")}
          {this.renderImpactFactorInput(values.landfillSpaceSaved, errors.landfillSpaceSaved, Strings.landfillSpaceSaved, Strings.cubicMeter, "landfillSpaceSaved")}
        </div>
        <div className="addwastebill-flex-row-space-between">
          {this.renderImpactFactorInput(values.waterSaved, errors.waterSaved, Strings.waterSaved, Strings.gallons, "waterSaved")}
          {this.renderImpactFactorInput(values.reductionPetroleumUsed, errors.reductionPetroleumUsed, Strings.reductionPetroleumSaved, Strings.barrels, "reductionPetroleumUsed")}
        </div>
      </div>
    );
  }

  renderImpactFactorInput(value: any, error: any, labelName: any, unit: any, name: any) {
    return (
      <InputField
        value={value}
        type="text"
        name={name}
        data-testid={name}
        disable={true}
        customStyles={this.state.viewMode ? "disabled-dropdown" : ""}
        customStyle={this.state.viewMode ? { border: "1px solid #e0e2e7" } : {}}
        customContainerStyle="addwastebill-two-input-row"
        labelStyle={`addwastebill-bottom-input-color ${this.renderStyleOnLanguage("textAlignAddClass")}`}
        placeholder={"0"}
        labelName={`${labelName} (${unit})`}
        error={value === "" ? null : Boolean(error)}
        inputStyle={{ ...this.renderStyleOnLanguage("marginLeftInputFieldClient"), ...this.renderStyleOnLanguage("marginRightInputFieldClient") }}
      />
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
   let lang= localStorage.getItem("language")
    return (
      //Merge Engine DefaultContainer
      <div
        id="element"
        ref={this.scrollRef}
        className={`main-wrapper ${this.state.successModal &&
          "addwastebill-disable-scroll"}`}
      >
        <NavigationMenu navigation={this.props.navigation} id={"Waste Bill"} />
        {this.state.successModal && (
          <SuccessModal
            data-testid={"successModalComponent"}
            onModalCrossPress={this.onModalCrossPress}
            successText={Strings.wastebillSuccessfulAdded}
          />
        )}
        <div className="dashboard_container_wrapper">
          <div className="dashboard_navbar_container">
            <Navbar navigation={this.props.navigation} id={""} />
          </div>
          <div className="addNewWasteBill-right-container">
            <div className="contactus-header-container">
              {this.getContactUsHeaderTopContainer()}
            </div>
            <Formik
              data-testid="formik"
              initialValues={{
                wasteCatagory: this.defaultValues,
                clientName: this.defaultValues,
                branchName: this.defaultValues,
                recyclableNetWeight: "",
                reportDate: "",
                comment: "",
                attachments: [],
                treeSaved: "",
                energySaved: "",
                co2Reduction: "",
                landfillSpaceSaved: "",
                waterSaved: "",
                reductionPetroleumUsed: "",
              }}
              onSubmit={(values) => {
                this.handleSubmit(values);
              }}
              validationSchema={this.formSchema}
              innerRef={this.formRef}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleReset,
                  setFieldValue,
                } = props;
                return (
                  <Form
                    onDrop={this.handleFileDrop}
                    onDragEnter={this.handleDragEnter}
                    onDragLeave={this.handleDragLeave}
                    onDragOver={this.handleDragOver}
                    translate={undefined}
                  >
                    {this.getAddWasteBillFormTopContainer(values, errors, handleChange, handleBlur, handleReset, setFieldValue, lang)}
                    {!this.state.viewMode && (
                      <div
                        style={this.renderStyleOnLanguage("bottomButtonMargin")}
                        className="bottom-button-container addwastebill-bottom-buttons"
                        key={"bottomButtonContainer"}
                      >
                        <button
                          data-testid={"discardButton"}
                          className="add-button"
                          type="button"
                          onClick={this.handleReset}
                        >
                          <p className="add-button-text">
                            {Strings.discardChanges}
                          </p>
                        </button>
                        <button
                          data-testid={"saveButton"}
                          disabled={this.state.btnDisableFlag}
                          className={`add-button button-fill`}
                          type="submit"
                        >
                          <p className="add-button-text">{Strings.save}</p>
                        </button>
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  backButtonImg: {
    height: "1.5rem",
    width: "1.5rem",
    marginRight: "0.75rem",
  },
  cursorPointer: {
    cursor: "pointer",
  },
} as const;

// Customizable Area End
