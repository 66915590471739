import React from "react";
import "./ClientDetailsStyles.css";
import "./SendInviteStyles.css";
import AccountCreationHeaderController from "./AccountCreationHeaderController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import ClientDetails from "./ClientDetails.web";
import SendInvite from "./SendInvite.web";
import { tickIcon } from "./assets";
import ClientDetailsReport from "./ClientDetailsReport.web";
import ContractDetails from "./ContractDetails.web";
import Strings from "./Strings";
import Navbar from "../../settings2/src/Navbar.web";

class AccountCreationHeader extends AccountCreationHeaderController {
  getCurrentScreen = () => {
    switch (this.state.currentScreen) {
      case Strings.clientDetails:
        return (
          <ClientDetails
            navigation={this.props.navigation}
            id={""}
            setCurrentScreen={this.setCurrentScreen}
            editId={this.state.editId}
          />
        );
      case Strings.sendInvite:
        return (
          <SendInvite
            navigation={this.props.navigation}
            id={""}
            setShowSuccessModal={this.setShowSuccessModal}
            setError={this.setError}
            scrollToTop={this.scrollToTop}
            editId={this.state.editId}
          />
        );
      case Strings.report:
        return (
          <ClientDetailsReport
            navigation={this.props.navigation}
            id={""}
            setError={this.setError}
            setCurrentScreen={this.setCurrentScreen}
            editId={this.state.editId}
          />
        );

      case Strings.contract:
        return (
          <ContractDetails
            navigation={this.props.navigation}
            id={""}
            setCurrentScreen={this.setCurrentScreen}
            setError={this.setError}
            editId={this.state.editId}
          />
        );
    }
  };

  getErrorBoxStyles = () => {
    if (this.state.error.status === null) {
      return "";
    } else if (!this.state.error.status) {
      return "prompt-container-success";
    } else {
      return "prompt-container-failure";
    }
  };

  render() {
    let { navigation } = this.props;
    let screenTitle = [
      Strings.clientDetails,
      Strings.contract,
      Strings.report,
      Strings.sendInvite,
    ];

    return (
      <div className={`account-creation-outer-container`}>
        {this.state.showSuccessModal && (
          <div className="success-modal-container">
            <div className="success-modal-inner-container">
              <img src={tickIcon} className="modal-image" />
              <span className="modal-text">{Strings.inviteSent}</span>
            </div>
          </div>
        )}
        <div
          className={`main-wrapper account-creation-inner-cont`}
          ref={this.scrollRef}
        >
          <NavigationMenu navigation={navigation} id={""} />
          <div className="account-creation-container scroll_account">
            <Navbar navigation={navigation} id={""} />
            <div className="header-container">
              <div className="header-top-container">
                <p className="header-title">
                  {this.state.editId === -1
                    ? Strings.registerNewClient
                    : Strings.editClient}
                </p>
                {this.state.error.status !== null && (
                  <span
                    className={`prompt-container header-prompt-container ${this.getErrorBoxStyles()}`}
                  >
                    {this.state.error.message}
                  </span>
                )}
              </div>
              <div className="screen-title-container">
                <div className="screen-title-inner-container">
                {screenTitle.map((item, index) => (
                  <div
                    data-testid={`changeScreenButton${index}`}
                    key={index}
                    onClick={() => this.onHeaderScreenClick(item)}
                    className={`client-titles-inner-container ${
                      item === this.state.currentScreen
                        ? "current-client-screen"
                        : ""
                    }`}
                  >
                    <p
                      className={`client-titles ${
                        item === this.state.currentScreen
                          ? "current-client-screen-title"
                          : ""
                      }`}
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>
              </div>
            </div>
            {this.getCurrentScreen()}
          </div>
        </div>
      </div>
    );
  }
}

export default AccountCreationHeader;
