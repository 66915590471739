import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { numberRegex } from "./assets";
import moment from "moment";
import Strings from "./Strings";

// Customizable Area Start

export type Selected = "Client Details" | "Contract" | "Report" | "Send Invite";

interface Data {
  company_name: string;
  client_email: string;
  full_phone_number: string;
  address: string;
  report_frequency: string;
  activated: boolean;
  city: string;
  email: string;
  role: string;
  password: string;
  password_confirmation: string;
  first_name: string;
  last_name: string;
  frequency_of_pickup_start_date: "09-08-2023";
  pick_up_frequency: string;
  indoor_recycle_bin: number;
  outdoor_recycle_bin: number;
  first_pickup_date: "20-08-2023";
  payment_term_frequency: string;
  waste_type_category_ids: [1, 2];
  awareness_given: ["Online"];
  account_attachments: [];
  contact_people_attributes: Array<ContactPerson>;
  branch_names_attributes: Array<BranchName>;
  contracts_attributes: Array<ContractAttributes>;
  due_dates_attributes: Array<DueDatesAttributes>;
}

interface DueDatesAttributes {
  due_date: string;
  recurring_payment: boolean;
  recurring_day: number;
  recurring_tenure: string;
  recurring_on: boolean;
  recurring_ends_on: string;
  recurring_ends_on_date: string;
  recurring_ends_after: boolean;
  recurring_occurrence: number;
}

interface ContractAttributes {
  start_date: string;
  end_date: string;
}

interface ContactPerson {
  name: string;
  email: string;
  mobile_number: string;
}
interface BranchName {
  name: string;
  location: string;
  city: string;
}

interface Error {
  status: boolean | null;
  message: string;
}

interface DueDate
{ 
  id : number;
  isDbSaved : boolean;
  due_date : string | null,
  recurring_payment : boolean,
  recurring_day:string,
  recurring_tenure : string;
  recurring_on : string;
  recurring_ends_on : boolean;
  recurring_end_date : string;
  recurring_ends_after : boolean;
  recurring_occurrence : string;
  recurring_on_drop_down : string;
  _destroy : boolean;
  recurring_tenure_drop_down : string,
}

interface DropdownOptions
{ 
  label : string;
  value : string
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;

  setError: (arg: any) => void;
  setCurrentScreen : (arg:string) => void
  // Customizable Area Start
  editId? : number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentScreen: string;
  data: any;
  error: Error;
  showSuccessModal:boolean;
  contractDates : Array<any>;
  rowId : number;
  indoorRecycle : number;
  outdoorRecycle : number;
  inCount : number;
  outCount : number;
  frequency: DropdownOptions;
  firsPickupDate : string;
  pickupFrequency : any;
  pickupDate : string;
  initData : any;
  dueDatesList : Array<DueDate>;
  dId : number,
  deletedContracts : any[];
  deletedDueDates : any[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ContractDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendDetailsId : string = "";
  getDetailsId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      currentScreen: Strings.sendInvite,
      data: {},
      error: {
        status: null,
        message: "",
      },
      rowId : 0,
      frequency : {label:Strings.selectFrequency, value : ""},
      firsPickupDate: "",
      showSuccessModal:false,
      contractDates : [{
        id : 0,
        start_date : '',
        end_date : '',
        _destroy : false
      }],
      pickupFrequency: {label:Strings.selectFrequency, value : null},
      pickupDate : '',
      indoorRecycle : 0,
      outdoorRecycle : 0,
      inCount : 0,
      outCount : 0,
      dueDatesList : [{
        id : 1, 
        due_date : "",
        recurring_payment : true,
        recurring_tenure : "",
        recurring_on : "",
        recurring_ends_on : false,
        recurring_end_date : "",
        recurring_ends_after : false,
        recurring_occurrence : "",
        recurring_day: "",
        recurring_on_drop_down : "",
        recurring_tenure_drop_down : "",
        isDbSaved : false,
        _destroy : false
      }],
      dId : 1,
      initData : {},
      deletedContracts : [],
      deletedDueDates : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage))  
      const successMessage = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.sendDetailsId
    ) {
      this.sendDetailsId = "";
      this.handleClientAPIResponseMessage(
        successMessage,
        errorMessage,
        this.clientSuccessCallback
      );
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getDetailsId
    )
    {
      if(!successMessage.errors)
      {
        this.setInitialValues(successMessage.data)
        this.setState({initData : successMessage.data})
      }

    }

    // Customizable Area End
  }

  //Customizable Area Start

  setInitialValues(data : any)
  {
    let attributes = data.attributes;
     this.setState({
      frequency : {label: attributes.payment_term_frequency, value : attributes.payment_term_frequency_value},
      firsPickupDate : attributes.first_pickup_date,
      inCount : attributes.indoor_recycle_bin ? attributes.indoor_recycle_bin : 0,
      outCount : attributes.outdoor_recycle_bin ? attributes.outdoor_recycle_bin : 0,
      pickupFrequency : {label : attributes.pick_up_frequency, value : attributes.pick_up_frequency_value},
      pickupDate : attributes.frequency_of_pickup_start_date,
      indoorRecycle : attributes.indoor_recycle_bin ? attributes.indoor_recycle_bin : 0,
     })
     this.setIntialContractDates(data)
     this.setIntialDueDates(data);
  }

  setDynamicMargins = () => {
    const appLanguage = localStorage.getItem('language');
    if (appLanguage === 'arabic') {
      // Arabic-specific margins
      document.documentElement.style.setProperty('--dynamic-margin', '0 2rem 0 8.8rem');
      document.documentElement.style.setProperty('--dynamic-margin-small', '0 2rem 0 1rem');
    } else {
      // Default margins
      document.documentElement.style.setProperty('--dynamic-margin', '0 8.8rem 0 2rem');
      document.documentElement.style.setProperty('--dynamic-margin-small', '0 1rem 0 2rem');
    }
  };
  

  setIntialContractDates(data : any)
  {
    if(data.attributes.contracts && data.attributes.contracts.length > 0)
    {
      let contract = data.attributes.contracts;
      for(let i = 0; i<contract.length; i++)
      {
        contract[i].isDbSaved = true;
        contract[i].id = contract[i].contract_id
      }
      this.setState({
       contractDates : contract
      })
    }
  }


  setIntialDueDates(data : any)
  {
    let dueDate = data.attributes.due_dates;
    if(data.attributes.due_dates && data.attributes.due_dates.length > 0)
    {
    for(let i=0; i<dueDate.length; i++)
    {
      dueDate[i].recurring_tenure_drop_down = {label : dueDate[i].recurring_tenure, value : dueDate[i].recurring_tenure_value}
      dueDate[i].recurring_on_drop_down = {label : dueDate[i].recurring_on, value : dueDate[i].recurring_on_value}
      dueDate[i].recurring_on = dueDate[i].recurring_on_value;
      dueDate[i].recurring_tenure_drop_down = {label : dueDate[i].recurring_tenure, value : dueDate[i].recurring_tenure_value}
      dueDate[i].recurring_end_date = dueDate[i].recurring_ends_on_date;
      dueDate[i].isDbSaved = true;
      dueDate[i].id = dueDate[i].due_date_id
      dueDate[i].recurring_tenure = dueDate[i].recurring_tenure_value
    }
  
      this.setState({
       dueDatesList : dueDate
      })
    }
  }

  getDetailsById()
  {

    let token = localStorage.getItem('token')
    if (token) {
      const header = {
        token: token,
        "Content-Type": configJSON.contentTypeApiGetClientAccountDetail,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getDetailsId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiGetClientAccountDetail + this.props.editId
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetClientAccountDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  async componentDidMount(){
    super.componentDidMount();
    if(this.props.editId && this.props.editId != -1)
    {
      this.getDetailsById()
    }
    this.setDynamicMargins()
  }

  addRow = (item : any) => {

    if(this.setPrevDates())
    {
      this.setState({rowId : this.state.rowId + 1},() => {
    let row = {id : this.state.rowId ,start_date : '' , end_date : ''}
    let arr = [...this.state.contractDates, row]
    this.setState({contractDates : arr})
      })
    }
  }

  setPrevDates()
  {
    let contracts = this.state.contractDates;
    for(let i=0;i<this.state.contractDates.length; i++)
    {
      if(contracts[i].start_date.trim() && contracts[i].end_date.trim())
      {
      contracts[i].isSaved = true
      }
      else{
      return false;
      }
    }
    this.setState({contractDates : contracts})
    return true;
  }

  formateDate(date : string)
  {
    let formated = moment(date).format('DD/MM/YYYY') ;
    return formated.toString()
  }

  covertDateFormate(date : string)
  {
    const parsedDate = moment(date, 'DD/MM/YYYY', true); 
    let formated = moment(parsedDate).format('YYYY-MM-DD') ;
    return formated.toString()
  }



  addDueDateRow()
  { 
    let id = this.state.dId + 1;

    this.setState({dId : id}, () => {

    let row = {
      id : this.state.dId, 
      isDbSaved : false,
      due_date : "",
      recurring_payment : true,
      recurring_tenure : "",
      recurring_on : "",
      recurring_ends_on : false,
      recurring_end_date : "",
      recurring_ends_after : false,
      recurring_occurrence : "",
      recurring_day: "",
      recurring_on_drop_down: "",
      _destroy : false,
      recurring_tenure_drop_down : ""
    }
    let arr = [...this.state.dueDatesList, row]
    this.setState({dueDatesList : arr})

  })
  }

  deleteFromArray(arr : Array<any>,id : any)
  {
    let elem = arr.filter(item => item.id != id);
    return elem;
  }

  deleteRow(item : any)
  { 
    let arr = this.state.contractDates;
    let row = arr.filter(row => row.id == item.id)[0];
    if(row && row.isDbSaved)
    {
      let deleted = this.state.deletedContracts;
      row._destroy = true
      deleted.push(row);
      this.setState({deletedContracts : deleted})
    }
   
    
    arr = this.deleteFromArray(arr,row.id);
    this.setState({contractDates : arr})
    
  }

  deleteRowDueDate(item : any)
  { 
    let arr = this.state.dueDatesList;
    let row = arr.filter(row => row.id == item.id)[0];
    if(row && row.isDbSaved)
    {
      let deleted = this.state.deletedDueDates;
      row._destroy = true
      deleted.push(row);
      this.setState({deletedDueDates : deleted})
    }
    
    arr = this.deleteFromArray(arr,row.id);
    this.setState({dueDatesList : arr})
    
  }

  startDateHandle(type : string, item : any,event: any)
  { 
    let list = this.state.contractDates;
    switch(type){
      case 'start' :
    list.forEach((row : any) => { 
        if(row.id == item.id )
        { 
          row.start_date = this.formateDate(event.target.value) === 'Invalid date' ? '' : this.formateDate(event.target.value);
        }
    })
    this.setState({contractDates : list})
    break;

    case 'end' :
      case 'start' :
        list.forEach((row : any) => { 
            if(row.id == item.id )
            { 
              row.end_date = this.formateDate(event.target.value) === 'Invalid date' ? '' : this.formateDate(event.target.value);
            }
        })
        this.setState({contractDates : list})
        break;
  }
  }

  handleFrequencySelect(selectedOption : any)
  {
    this.setState({frequency : selectedOption})
  }

  getDisableState(): boolean
  { 
    if((this.state.contractDates.length < 1 || this.state.dueDatesList.length < 1))
    {
      return true;
    }
    else if(!this.checkValidations())
    { 
      return true
    }
    else{ 
      return false;
    }
  }

  firstPickupHandle(event : any)
  { 
    this.setState({firsPickupDate : moment(event.target.value).isValid() ? this.formateDate(event.target.value) : ''})
  }

  handlePickupFrequency(event : any)
  { 
    this.setState({pickupFrequency : event})
  }

  handlePickupDate(event : any)
  { 
    this.setState({pickupDate :  moment(event.target.value).isValid() ? this.formateDate(event.target.value) : ''})
  }

  handleRecycle()
  { 
    this.setState({inCount : this.state.inCount + 1})
  }

  handleRecycleOut()
  { 
    this.setState({outCount:this.state.outCount + 1})
  }

  handleRecycleDec()
  {
    this.setState({inCount : this.state.inCount - 1})
  }

  handleRecycleOutDec()
  {
    this.setState({outCount : this.state.outCount - 1})
  }

  repeatOnHandle(event:any,item : any)
  { 
    let list = this.state.dueDatesList;
    list.forEach(this.repeatOnCallback.bind(this,item,event))
  this.setState({dueDatesList : list})
  }

  repeatOnCallback = (item:any, event:any, row:any) => { 
    if(row.id == item.id)
    { 
      row.recurring_on_drop_down = event
      row.recurring_on = event.value;
    }
  }

  dueDateHandle(event:any,item : any)
  { 
    let list = this.state.dueDatesList;
    list.forEach(this.dueDateCallback.bind(this,item,event))
  this.setState({dueDatesList : list})
  }

  dueDateCallback = (item:any, event:any, row:any) => { 
    if(row.id == item.id)
    { 
      row.due_date =  moment(event.target.value).isValid() ? this.formateDate(event.target.value) : '';
    }
  }

  recurringCheckbox(event:any, item : any)
  { 
    let list = this.state.dueDatesList;
    list.forEach(this.recurringCheckboxCallBack.bind(this,item,event))
  this.setState({dueDatesList : list})
  }


  recurringCheckboxCallBack = (item:any, event:any, row:any) => { 
    if(row.id == item.id)
    { 
      row.recurring_payment = event.target.checked;
    }
  }

  repeatEveryHandle(event:any, item : DueDate)
  { 
    let list = this.state.dueDatesList;
    list.forEach(this.repeatEveryCallBack.bind(this,item,event))

  this.setState({dueDatesList : list})
  }

  repeatEveryCallBack = (item:any, event:any, row:any) => { 
    if(row.id == item.id && event.target.value.match(numberRegex))
    { 
      row.recurring_day = event.target.value;
    }
  }

  frequencyHandle(event:any, item : any)
  { 
    let list = this.state.dueDatesList;
    list.forEach(this.frequencyCallBack.bind(this,item,event))

  this.setState({dueDatesList : list})
  }

  frequencyCallBack = (item:any, event:any, row:any) => { 
    if(row.id == item.id)
    { 
      row.recurring_tenure_drop_down = event;
      row.recurring_tenure = event.value
    }
  }

  handleOnRadio(event : any,item : any)
  { 
    let list = this.state.dueDatesList;
    list.forEach(this.handleOnRadioCallback.bind(this,item,event))

  this.setState({dueDatesList : list})
  }

  handleOnRadioCallback = (item:any, event:any, row:any) => { 
    if(row.id == item.id)
    { 
      row.recurring_ends_on = event.target.checked;
      row.recurring_ends_after = !row.recurring_ends_on;     
      row.recurring_occurrence = null;
    }
  }

  endsOnDateHandle(event :any,item : any)
  { 
    let list = this.state.dueDatesList;
    list.forEach(this.endOnDateCallBack.bind(this,item,event));

  this.setState({dueDatesList : list})
  }

  endOnDateCallBack = (item:any, event:any, row:any) => { 
    if(row.id == item.id)
    { 
      row.recurring_end_date =  this.formateDate(event.target.value);
    }
  }

  occurenceHandle(event: any, item: any) {
    let list = this.state.dueDatesList;
    list.forEach(this.handleCallback.bind(this,item,event));

    this.setState({ dueDatesList: list });
  }
handleCallback = (item:any, event:any, row:any) => {
 
  if (row.id == item.id && numberRegex.test(event.target.value)) {
    row.recurring_occurrence = event.target.value;
  }
}
  
  afterRadioHandle(event : any, item : any)
  { 

    let list = this.state.dueDatesList;
    list.forEach(this.afterRadioCallBack.bind(this,item,event))

  this.setState({dueDatesList : list})
  }

  afterRadioCallBack = (item:any, event:any, row:any) =>
  {
    if(row.id == item.id)
    { 
      row.recurring_ends_after = event.target.checked;
      row.recurring_ends_on = !row.recurring_ends_after;
      row.recurring_end_date = null;
    }
  }

  checkForContractDates() : number
  { 
    let errorCount = 0;
    if(this.state.contractDates.length > 0)
    { 
      this.state.contractDates.forEach((date : any) => { 
        if(date.start_date == '' || date.end_date == '')
        { 
          errorCount ++;
        }
      })
    }

   
    return errorCount;
  }

  checkForDueDates() : number
  {
    let errorCount = 0; 
    if(this.state.dueDatesList.length > 0)
    { 
      this.state.dueDatesList.forEach((row:any) => { 
        if(row.due_date == '')
        { 
          errorCount ++
        }
        if(row.recurring_payment)
        { 
          if(row.recurring_day == '' || row.recurring_day == null
          || row.recurring_tenure == '' || row.recurring_tenure == null 
          || row.recurring_on == '' || row.recurring_on == null || (!row.recurring_end_date && (!row.recurring_occurrence || row.recurring_occurrence == '')))
          { 
            errorCount ++
          }
        }


      })
    }
    return errorCount
  }


  checkForPaymentTerms()
  {
    let errorCount = 0;
    if(this.state.frequency.label == "" || this.state.firsPickupDate == "")
    {
        errorCount = errorCount + 1;
    }

    return errorCount
  }

  checkValidations() : boolean
  { 
    let errorCount = 0;
    errorCount = this.checkForContractDates();
    errorCount = errorCount + this.checkForDueDates();
    errorCount = errorCount + this.checkForPaymentTerms()
    if(errorCount > 0)
    { 
      return false;
    }
    else{ 
      return true
    }
  }

  addDeletedDueDate()
  {
    let deleted = this.state.deletedDueDates
    for(let i=0; i<this.state.deletedDueDates.length ; i++)
    {
      deleted[i]._destroy = true
    }
    this.setState({deletedDueDates : deleted})
    return deleted;
  }


  addDeletedContracts()
  {
    let deleted = this.state.deletedContracts
    for(let i=0; i<this.state.deletedContracts.length ; i++)
    {
      deleted[i]._destroy = true
    }
    this.setState({deletedContracts : deleted})
    return deleted;
  }

  save()
  {
    
    let dueDateSend : any[]= [];
    dueDateSend =this.addDeletedDueDate()
   
    this.state.dueDatesList.forEach((row:DueDate) => { 
      let date : any = {
        due_date : row.due_date,
        recurring_payment : row.recurring_payment,
        recurring_tenure : row.recurring_tenure,
        recurring_on : row.recurring_on,
        recurring_ends_on : row.recurring_ends_on,
        recurring_ends_on_date : row.recurring_end_date,
        recurring_ends_after : row.recurring_ends_after,
        recurring_occurrence : row.recurring_occurrence,
        recurring_day: row.recurring_day,
      }

      if(row.isDbSaved)
      {
        date.id = row.id;
      }

      dueDateSend.push(date)
    })

    this.setState({contractDates : this.state.contractDates.concat(this.addDeletedContracts())},
    () => {
    const data ={
      account: {
          step: "Step2",
          contracts_attributes: this.formatContract(),
          payment_term_frequency: this.state.frequency.value,
          first_pickup_date: this.state.firsPickupDate,
          due_dates_attributes: dueDateSend,
          frequency_of_pickup_start_date: this.state.pickupDate,
          pick_up_frequency: this.state.pickupFrequency.value,
          indoor_recycle_bin: this.state.inCount,
          outdoor_recycle_bin: this.state.outCount,
  
  }
  }
  this.sendTermsConds(data)

})

}
  
  
  

  discard()
  {
      this.setState({contractDates: [],
        dueDatesList : [],
        frequency: {label:Strings.selectFrequency, value:"0"},
        pickupDate : "",
      firsPickupDate: "",
      pickupFrequency: {label : Strings.selectFrequency, value:"0"},
      dId : 0,
      indoorRecycle: 0,
      inCount : 0,
      outCount : 0,
      rowId : 0,}, () => {
    if(this.props.editId && this.props.editId !== -1)
    {
      this.setInitialValues(this.state.initData)
      this.setState({deletedContracts : [], deletedDueDates : []})
    }
  })
  
  }

  sendTermsConds = (payload : any) => {
    const token = localStorage.getItem("token");
    const accountId = this.props.editId && this.props.editId !== -1 ? this.props.editId : localStorage.getItem("clientAccountId")
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.contractDetailSendEndPoint}${accountId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  clientSuccessCallback = async (successMessage: any) => {
    this.setState({
      error: { status: false, message: Strings.changesSaved },
    });
    this.props.setCurrentScreen(Strings.report);
  };


  handleClientAPIResponseMessage(
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) {   
   if (successMessage) {
      if (successMessage.errors) {
        let error = {
          error: { status: true, message: successMessage.errors[0] },
        }
        if (successMessage.errors[0].token) {
          this.props.navigation.navigate("EmailAccountLoginBlock");
        } else {
          this.props.setError({
            error: { status: true, message: successMessage.error[0] },
          });
        }
      } else {
        callback(successMessage);
      }
    }
  }

  formatContract()
  {
    let contract = []

    for(let i=0; i<this.state.contractDates.length;i++)
    {
      if(!this.state.contractDates[i].isDbSaved)
      {
      let data = {start_date: "", end_date: ""}

      data.start_date = this.state.contractDates[i].start_date
      data.end_date = this.state.contractDates[i].end_date

      contract.push(data)
      }
      else{
        contract.push(this.state.contractDates[i])
      }
    }
    return contract
  }


 
  // Customizable Area End
}
