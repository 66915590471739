import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { TimeChoice, listLabelMonth } from "./DashboardController.web";
import moment from "moment";

export const createRequest = (request: {
  requestMsg: Message;
  endPoint: string;
  method: string;
  token?: string | null;
  body?: string;
  header?: any;
}) => {
  const {
    requestMsg: apiMessage,
    endPoint: apiEndPoint,
    method: apiMethod,
    token: apiToken,
    body: bodyRequest,
    header,
  } = request;

  const convertHeader = {
    Token: apiToken ?? undefined,
    ...header,
  };

  apiMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPoint,
  );

  apiMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(convertHeader),
  );

  apiMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    apiMethod,
  );

  bodyRequest &&
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyRequest,
    );

  runEngine.sendMessage(apiMessage.id, apiMessage);
}

function handleMonthlyData(inputData: any, arrayDataLabel: string[], arrayDataData: number[]) {
  listLabelMonth.forEach((item: string) => {
    arrayDataLabel.push(item);
    arrayDataData.push(0);
  });

  const arrayObject: Array<any> = []
    for (const [key, value] of Object.entries(inputData)) {
      arrayObject.push({
        month: key.slice(0, 3),
        value: value
      })
    }
  fillMonthlyData(arrayObject, arrayDataData);
}

function fillMonthlyData(arrayObject: Array<{ month: string; value: any }>, arrayDataData: number[]) {
  for (const iterator of arrayObject) {
    const indexMonth = listLabelMonth.findIndex((item) => item === iterator.month);
    if (indexMonth !== -1) {
      arrayDataData[indexMonth] = iterator.value.recyclable_net_weight;
    }
  }
}

function handleYearlyData(inputData: any, arrayDataLabel: string[], arrayDataData: any) {
  for (const [key, value] of Object.entries(inputData)) {
    arrayDataLabel.push(key);
    arrayDataData.push(value);
  }
}

export function handleDefaultData(inputData: any, arrayDataLabel: string[], arrayDataData: any, isLineChart: boolean) {
    const arrayObject: Array<any> = []
    // Only display first 3 characters of month
    for (const [key, value] of Object.entries(inputData)) {
      arrayObject.push({
        year: Number(key),
        value: value
      })
    }

    // re order data
    if (arrayObject.length > 1) {
      arrayObject.sort(function (a: any, b: any) {
        return listLabelMonth.indexOf(a.year)
          - listLabelMonth.indexOf(b.year);
      });
    }

    for (const iterator of arrayObject) {
      arrayDataLabel.push(iterator.year)
      arrayDataData.push(iterator.value)
    }

    //if there is only 1 year in data, add 1 more year before it for line chart
    if (arrayDataLabel.length === 1 && isLineChart) {
      arrayDataLabel.unshift((Number(arrayDataLabel[0]) - 1).toString())
      arrayDataData.unshift(0)
    }
}

export function handleDateRangeData(inputData: any, startDate: any, endDate: any, arrayDataLabel: string[], arrayDataData: any) {
  if (startDate === "" && endDate === "") {
    const { start, end } = getStartEndDate(inputData);
    startDate = start;
    endDate = end;
  }
  const startDateInput = moment(startDate, "DD-MM-YYYY");
    const endDateInput = moment(endDate, "DD-MM-YYYY");
    const currentDate = moment();
    const effectiveEndDate = endDateInput.isSameOrBefore(currentDate, 'month') ? endDateInput : currentDate;

    const resultLabel = generateMonthLabels(startDateInput, effectiveEndDate);
    const arrayObject = createArrayObject(inputData);
    const resultValues = populateResultValues(arrayObject, resultLabel);

  arrayDataLabel.length = 0; // Clear existing labels
  arrayDataLabel.push(...resultLabel);
  arrayDataData.length = 0; // Clear existing data
  arrayDataData.push(...resultValues);
}

function getStartEndDate(inputData: any) {
  const keys = Object.keys(inputData);
  if (keys.length === 0) return { start: "", end: "" }; // Handle empty inputData

  const startDate = moment(keys[0], "MMMM YYYY").startOf('month').format("DD-MM-YYYY");
  const endDate = moment(keys[keys.length - 1], "MMMM YYYY").endOf('month').format("DD-MM-YYYY");

  return { start: startDate, end: endDate };
}

export function checkConditionForFilter(inputData: any, startDate: any, endDate: any, timeChoice: TimeChoice) {
  const startYear = moment(startDate, "DD-MM-YYYY").year();
  const endYear = moment(endDate, "DD-MM-YYYY").year();
  const uniqueYears = new Set(Object.keys(inputData).map(key => key.split(" ")[1]));

  return (startDate && endDate && startYear !== endYear && timeChoice === TimeChoice.MONTH) || (uniqueYears.size > 1 && timeChoice === TimeChoice.MONTH);
}

export function generateMonthLabels(startDate: any, endDate: any) {
  const labels = [];
  let current = startDate.clone();

  while (current.isSameOrBefore(endDate, 'month')) {
      labels.push(current.format("MMM-YY"));
      current.add(1, 'month');
  }

  return labels;
}

export function createArrayObject(inputData: any) {
  return Object.entries(inputData).map(([key, value]) => ({
      month: key.slice(0, 3),
      year: key.split(" ")[1].slice(-2),
      value: value
  }));
}

export function populateResultValues(arrayObject: any, resultLabel: any) {
  const resultValues = new Array(resultLabel.length).fill(0);

  for (const item of arrayObject) {
      const formattedMonth = `${item.month}-${item.year}`;
      const index = resultLabel.indexOf(formattedMonth);
      if (index !== -1) {
          resultValues[index] = item.value.recyclable_net_weight;
      }
  }

  return resultValues;
}

export const handleResponseDataObject = ({ inputData, timeChoice, isLineChart, startDate, endDate }: { inputData: any, timeChoice: TimeChoice, isLineChart: boolean, startDate: any, endDate: any }) => {
  let arrayDataLabel: Array<any> = []
  let arrayDataData: Array<any> = []

  if (timeChoice === TimeChoice.MONTH) {
    handleMonthlyData(inputData, arrayDataLabel, arrayDataData);
  } else if (timeChoice === TimeChoice.YEAR) {
    handleYearlyData(inputData, arrayDataLabel, arrayDataData);
  } else {
    handleDefaultData(inputData, arrayDataLabel, arrayDataData, isLineChart);
  }
  if (checkConditionForFilter(inputData, startDate, endDate, timeChoice)) {
    handleDateRangeData(inputData, startDate, endDate, arrayDataLabel, arrayDataData);
  }
  return {
    label: arrayDataLabel,
    data: arrayDataData
  }
}
