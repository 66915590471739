// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

import { BlockComponent } from "framework/src/BlockComponent";
import { SQS } from "./ClientListingController.web";


export interface Props {
  navigation: any;
  // Customizable Area Start
  searchQueryState:SQS,
  setSearchQueryState : (arg:SQS,callApi:boolean)=>void,
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  switchLanguage:string
  // Customizable Area End
}
interface SS {}

export default class ClientListSortController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      switchLanguage: '',
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
   super.componentDidMount();
   const currency = await getStorageData('language');
   this.setState({
   switchLanguage:currency
  })
}

  setSortType = (sortType:string)=>()=>{
    if(this.props.searchQueryState.sort_by !== sortType){
      this.props.setSearchQueryState({...this.props.searchQueryState,sort_by:sortType,page : 1,activePagiantionStep : 1},true)
    }
  }
  // Customizable Area End
}
